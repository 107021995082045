import React, {useState} from 'react';
import RequestAccessSent from '@/modules/authentication/pages/RequestAccess/RequestAccessSent';
import RequestAccessForm from '@/modules/authentication/pages/RequestAccess/RequestAccessForm';

function RequestAccess() {
  const [sent, setSent] = useState('');

  if (!sent)
    return <RequestAccessForm onSent={setSent}/>;

  return <RequestAccessSent email={sent}/>;
}

export default RequestAccess;

