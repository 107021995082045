import React, {createContext, useContext} from 'react';
import {ApiClientService, useUser} from '@dofleini/security';

const SessionControlContext = createContext();

const REFRESH_TOKEN_KEY = '__refresh_token__';
const EXPIRE_DATE_KEY = '__expire_date__';

export const setAuthData = (data = {}) => {
  const {accessToken, refreshToken, space} = data;
  ApiClientService.setToken(accessToken);
  ApiClientService.setSpace(space);
  window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  window.localStorage.setItem(EXPIRE_DATE_KEY, new Date().toISOString());
};

export const handleUserResponse = ({data}) => {
  setAuthData(data);
  return data;
};

const SessionControlProvider = (props) => {
  const {user} = useUser();


  return (
    <SessionControlContext.Provider value={user} {...props}/>
  );
};

const useSessionControl = () => {
  const context = useContext(SessionControlContext);
  if (context === undefined) {
    throw new Error('useSessionControl must be used within a SessionControlProvider');
  }
  const {user} = context;
  return {user};
};

SessionControlProvider.propTypes = {};


export {SessionControlProvider, useSessionControl};
