/* eslint-disable react-hooks/exhaustive-deps */
import React, {memo} from 'react';
import MainRouter from './MainRouter';
import MainLayout from '@/layout/MainLayout';
import 'antd/es/slider/style';
import NewEmailDialog from '@/components/NewEmailDialog';

const MainApp = () => {

  return (

    <MainLayout>
      <MainRouter/>
      <NewEmailDialog/>
    </MainLayout>
  );
};

export default memo(MainApp);
