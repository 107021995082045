import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Grid, Layout} from 'antd';
import PropTypes from 'prop-types';
import style from './index.less';
import {layout, LAYOUT_TYPE} from '@/settings';
import ResponsiveHeader from '@/layout/Horizontal/ResponsiveHeader';
import classNames from 'classnames';
import ResponsiveSider from '@/layout/Horizontal/ResponsiveSider';
import NavigationMenu from '@/layout/Menu/NavigationMenu';
import {Scrollbars} from 'react-custom-scrollbars';
import styled from 'styled-components';
import pick from 'lodash/pick';
import {useLocation} from 'react-router-dom';
import {useResponsive} from '@/contexts/ResponsiveContext';

const {useBreakpoint} = Grid;

const SiderBar = styled(Layout.Sider)`
  & > :first-child {
    display: flex;
    ${() => layout.type === LAYOUT_TYPE.HORIZONTAL && 'flex-direction: column;'}
  }
`;

const firstRenderCollapsed = () => {
  return document?.body?.clientWidth < 992;
};

const HorizontalLayout = ({children, ...props}) => {
  const {lg} = useBreakpoint();
  const [collapsed, setCollapsed] = useState(firstRenderCollapsed());
  const collapsedWidth = !lg ? 0 : layout.options.collapsedWidth;
  const toggleCollapsed = useCallback(() => setCollapsed(prevState => !prevState), []);
  const scrollRef = useRef(null);
  const siderBarOptions = useMemo(() => pick(layout.options, ['width', 'zeroWidthTriggerStyle', 'theme']), []);

  const {isMobile} = useResponsive();
  const location = useLocation();

  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    }
  }, [isMobile, location]);
  //
  // const trigger = <div
  //   className={classNames('sidebar-trigger', {[`position-${layout.options.triggerPosition}`]: !collapsed})}>
  //   <MenuFoldOutlined
  //     className={classNames('transition duration-200 ease-in-out trigger-item-1',
  //       {'transform rotate-180': collapsed})}/>
  //   {
  //     layout.options.useTriggerText &&
  //     <span
  //       className={classNames('transition duration-200 ease-in-out m-2 trigger-item-2', {'hidden': collapsed})}>
  //       {t('sidebarTrigger')}
  //     </span>
  //   }
  // </div>;
  return (
    <>
      <ResponsiveHeader className={'z-20'} toggleSider={toggleCollapsed} isMobile={isMobile}/>
      <div
        className={classNames(style.overlayer, {'hidden': collapsed}, 'lg:hidden')}
        onClick={toggleCollapsed}
      />
      <ResponsiveSider  {...layout.options} collapsed={collapsed} className={'hidden lg:block'}/>
      <SiderBar
        id="side-bar-nav" collapsible
        className={classNames(style.sider, 'fixed shadow h-screen left-0 z-40 lg:z-10')}
        collapsed={collapsed}
        trigger={null}
        onCollapse={setCollapsed}
        collapsedWidth={collapsedWidth}
        {...siderBarOptions}
      >
        <Scrollbars
          ref={scrollRef}
          id="scroll-bars"
          // This will activate auto hide
          autoHide
          // Hide delay in ms
          autoHideTimeout={1000}
          // Duration for hide animation in ms.
          autoHideDuration={200}
          style={{
            height: isMobile ? '100%' : 'calc(100% - 60px)'
          }}
        >
          <NavigationMenu {...layout.options} {...props} collapsed={collapsed}/>
        </Scrollbars>
        {/*{lg && <UserOptions collapsed={collapsed} {...layout.options} {...props} className={'pb-2'}/>}*/}
      </SiderBar>
      {
        children && <Layout className="relative">
          <div className={classNames(style.headerSpace)}/>
          {children}
        </Layout>
      }
    </>
  );
};

export default memo(HorizontalLayout);

HorizontalLayout.propTypes = {
  children: PropTypes.any
};
