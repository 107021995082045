import UserAvatar from '@/components/UserAvatar/index';
import React from 'react';
import {Typography} from 'antd';
import {getUserFullName} from '@/utils/userFullName';

const {Text, Title} = Typography;

// eslint-disable-next-line react/display-name,react/prop-types
export default (value, form, initialData = {}) => {
  const updatedData = form.getFieldsValue(true) || {};
  const user = {
    ...initialData,
    ...updatedData,
  };
  const fullName = getUserFullName(user);

  return (
    <div className={'flex items-center py-2'}>
      <UserAvatar user={{...user}} size={66}/>
      <div className={'ml-4'}>
        <Title level={4}>{fullName}</Title>
        <Text type={'secondary'}>{user?.email}</Text>
      </div>
    </div>
  );
};
