import React from 'react';
import {AiOutlineExport, AiOutlineUser} from 'react-icons/ai';
import {BsGear} from 'react-icons/bs';

const guia = process.env.REACT_APP_GUIA_URL || 'http://localhost:3000';

export const options = [
  {
    divider: true
  },
  {
    title: 'Mi perfil',
    action: 'PROFILE',
    icon: <span className={'anticon'}><AiOutlineUser className={'transform'}/></span>,
  },
  {
    title: 'Ir a la guía',
    link: `${guia}/`,
    icon: <span className={'anticon'}><BsGear className={'transform'}/></span>,
  },
  {
    divider: true,

  },
  {
    title: 'Cerrar sesion',
    action: 'LOGOUT',
    icon: <span className={'anticon'}><AiOutlineExport className={'transform'}/></span>,
  }
];
