import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Progress } from 'antd';
import {scorePassword} from '@dofleini/validator';
import {EyeInvisibleOutlined, EyeTwoTone, SyncOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import style from './index.less';
import classNames from 'classnames';
import {generate} from '@/utils/passGenerator';


const getStrength = (val) => {
  if (val < 12)
    return 'veryWeak';
  if (val <= 24)
    return 'weak';
  if (val < 40)
    return 'acceptable';
  if (val < 60)
    return 'good';

  return 'Excellent';
};

const Password = ({onChange, lateral, size, ...props}) => {
  const {t} = useTranslation('common');

  const [score, setScore] = useState({score: 0, percent: 0});

  const handleChange = useCallback((e) => {
    const currentScore = scorePassword(e.target.value);
    const percent = Math.min(currentScore / 70 * 100, 100);
    setScore({score: currentScore, percent});
    onChange && onChange(e);
  }, [onChange]);

  useEffect(() => {
    if (props.value) {
      const currentScore = scorePassword(props.value);
      const percent = Math.min(currentScore / 70 * 100, 100);
      setScore({score: currentScore, percent});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGenerate = useCallback(() => {
    onChange(generate());
  }, [onChange]);

  const valid = useMemo(() => score?.score > 24, [score]);

  const color = useMemo(() => !valid ? '#ff4d4f' : score?.score < 40 ? '#faad14' : '#52c41a', [score, valid]);

  const strength = getStrength(score.score);

  return (
    <div className={'relative'}>
      <div className="flex">
        <Input.Password
          type="password"
          className={'rounded-r-none border-r-0'}
          required
          iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
          size={size}
          {...props}
          onChange={handleChange}
        />
        <Button onClick={onGenerate} className={'rounded-l-none'} icon={<SyncOutlined/>} size={size}/>
      </div>

      <div className={classNames(style.passMessage, {[style.lateral]: lateral})}>
        <span
          className={classNames({'text-error': !valid}, {'text-success': valid && score.score >= 40}, {'text-warning': valid && score.score < 40})}>
          {t('strength')}: {t(strength)}
        </span>
        <Progress
          percent={score.percent}
          size="small"
          strokeColor={color}
          status={valid ? 'success' : 'exception'}/>
        {t('passwordStrength')}
      </div>
    </div>
  );

};

export default memo(Password);

Password.propTypes = {
  lateral: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  size: PropTypes.any,
};

Password.defaultProps = {
  lateral: true
};
