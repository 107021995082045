import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {useDialogsContext} from '@/contexts/DialogsContext';
import {DIALOG_NAMESPACE} from '@/components/NewEmailDialog';

const Email = ({children, item, ...props}) => {
  const {openDialog: showEmail} = useDialogsContext(DIALOG_NAMESPACE);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    showEmail({users: [item]});
  }, [item, showEmail]);

  return (
    <Button type={'link'} size={'small'} className="px-0" {...props} onClick={handleClick}>{children}</Button>
  );
};

export default memo(Email);

Email.propTypes = {
  children: PropTypes.any,
  item: PropTypes.any,
};
