import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';

const DatePickerCmp = ({ value, onChange, innerRef, ...rest }) => {
  return (
    <div className={'w-full'}>
      <DatePicker
        {...rest}
        ref={innerRef}
        className={'w-full'}
        value={value ? moment(value) : null}
        onChange={onChange}/>
    </div>
  );
  
};
export default memo(DatePickerCmp);

DatePickerCmp.propTypes = {
  innerRef: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.any
};
