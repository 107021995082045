import Password from '@/modules/authentication/components/Password';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Tag} from 'antd';
import {EditOutlined} from '@ant-design/icons';

const FieldPassword = (props) => {
  const {t} = useTranslation('admin');
  const [isEditing, setIsEditing] = useState();

  return <div>
    {
      isEditing
        ? <Password
          lateral={false}
          size={'large'}
          placeholder={t('user.form.password.placeholder')}
          {...props}
        />
        : <Tag color="green" className="w-full pb-0.5 pt-2 flex items-center justify-between">
          <div className="text-2xl">
            ********
          </div>
          <EditOutlined className="float-right cursor-pointer text-lg mr-2" onClick={() => setIsEditing(true)}/>
        </Tag>
    }

  </div>;
};

export default FieldPassword;

export const renderFieldPassword = (props) => <FieldPassword {...props}/>;
