import React, {memo} from 'react';
import OwnerCard from '@/components/OwnerCard';
import WelcomeLeftSidebar from '@/components/WelcomeLeftSidebar';
import FullLayout from '@/modules/authentication/components/Layout/FullLayout';


const NoBusiness = () => {
  return (
    <FullLayout className={'w-full'}>
      <WelcomeLeftSidebar>
        <OwnerCard/>
      </WelcomeLeftSidebar>
    </FullLayout>
  );
};

export default memo(NoBusiness);
