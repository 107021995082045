import React from 'react';
import {useTranslation} from 'react-i18next';

export default ({translation, title}) => {

  // eslint-disable-next-line react/display-name
  return () => {
    const {t} = useTranslation(translation);

    return <fieldset  className={'mt-4'}>
      <legend style={{fontSize: '14px'}}>{t(title)}</legend>
    </fieldset>;
  };
};
