import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown} from 'antd';
import {DeleteOutlined, EditOutlined, EllipsisOutlined, EyeOutlined} from '@ant-design/icons';
import NavMenu from '@/layout/Menu';
import {useTranslation} from 'react-i18next';

const optionsEntity = [
  {
    icon: <EyeOutlined/>,
    title: 'details',
    link: '/details',

  },
  {
    icon: <EditOutlined/>,
    title: 'edit',
    link: '/option8',
    style: {minWidth: 150}
  },
  {
    divider: true,
  },
  {
    icon: <DeleteOutlined/>,
    title: 'remove',
    link: '/option8',
  }
];

const EntityMenu = ({options, translate, onClick, isLoading, children, mapOptions, hide, ...props}) => {
  const {t} = useTranslation(translate);

  const optionsMapped = useMemo(() => {
    if (typeof mapOptions === 'function') {
      return mapOptions(options, hide, t);
    }
    return options;
  }, [mapOptions, options, hide, t]);

  //handle ant issue, we need to call NavMenu as a function instead of component
  const menu = NavMenu({menu: optionsMapped, onClick: onClick});

  return <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" {...props}>
    {
      children || <Button loading={isLoading} icon={<EllipsisOutlined/>} className={'btn-big-icon'} type={'text'}/>
    }
  </Dropdown>;
};

export default memo(EntityMenu);

EntityMenu.propTypes = {
  data: PropTypes.object,
  hide: PropTypes.object,
  options: PropTypes.array,
  onClick: PropTypes.func,
  translate: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.any,
  mapOptions: PropTypes.any,
};

EntityMenu.defaultProps = {
  options: optionsEntity,
  translate: 'common',
  mapOptions: (options, hide, t) => options.filter(op => !hide || !hide[op.title]).map(op => ({
    ...op,
    title: t(op.title)
  }))
};
