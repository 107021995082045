import React, {memo} from 'react';
import {Button, Layout} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import style from './index.less';
import UserOptions from '@/components/UserOptions';
import SpaceIndicator from '@/components/SpaceIndicator';


const ResponsiveHeader = ({className, toggleSider, isMobile}) => {
  return (
    <Layout.Header id="app-top-header"
      className={classNames('fixed w-full flex-row flex shadow pr-6 items-center', className, style.header)}>
      <div className={'flex-grow flex items-center'}>
        <div className={'lg:px-3'}>
          <Button type={'text'} size={'large'}
            className={classNames(style.menuButton)}
            onClick={toggleSider}>
            <MenuOutlined/>
          </Button>
        </div>
        <SpaceIndicator mini={isMobile}/>
      </div>
      <UserOptions/>
    </Layout.Header>
  );

};

export default memo(ResponsiveHeader);

ResponsiveHeader.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  toggleSider: PropTypes.func
};
