export const getloadingArray = (number, key = '_id', props = {}) => {
  const data = [];
  for (let i = 0; i < number; i++) {
    data.push({[key]: i, __isLoading: true, ...props});
  }
  return data;
};

export const getBase64 = (file, cb) => {
  // eslint-disable-next-line no-undef
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
};
