import {ApiClientService, EntityApiService} from '@dofleini/security';
import {authentication} from '@/settings';
import {handleResponse} from '@/utils/requests';

class SpaceApiService extends EntityApiService {
  getMySpaces = () => {
    return handleResponse(ApiClientService.get(this.getPath('/my-spaces')));
  }
  onboardComplete = (params) => {
    return handleResponse(ApiClientService.post(this.getPath('/onboard-complete'), params));
  }

  customUpdate = (params) => {
    return handleResponse(this.update(params));
  }
}

export default new SpaceApiService(`${authentication.path}/api/spaces`);
