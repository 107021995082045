import GroupProductModule from '@/modules/groupProduct';
import GalleryModule from '@/modules/gallery';
import JobOffersModule from '@/modules/jobOffers';
import BiddingModule from '@/modules/bidding';
import ServiceModule from '@/modules/service';
import AdminModule from '@/modules/admin';
import HomeModule from '@/modules/home';

export default {
  Gallery: {
    exact: false,
    path: '/gallery',
    component: GalleryModule
  },
  GroupProduct: {
    exact: false,
    path: '/groupproducts',
    component: GroupProductModule
  },
  JobOffers: {
    exact: false,
    path: '/job-offers',
    component: JobOffersModule
  },
  Service: {
    exact: false,
    path: '/services',
    component: ServiceModule
  },
  Bidding: {
    exact: false,
    path: '/biddings',
    component: BiddingModule
  },
  Home: {
    exact: true,
    path: '/',
    component: HomeModule
  },
  User: {
    exact: false,
    path: '/admin',
    component: AdminModule
  }
};
