import { getAbsoluteURLPath } from '@/settings/utils';

const source = process.env.REACT_APP_FILE_STORAGE;
const prefixSource = process.env.REACT_APP_PREFIX_FILE_STORAGE || 'uploads';

const sourcePath = `${source}${prefixSource}`;

export const getFromSource = (image, absolutePath) => {
  if (!image) return '';
  const isSocialOrCDNAvatar =
    (image && image.startsWith('http')) || (image && image.startsWith('https'));
  return isSocialOrCDNAvatar ? image : absolutePath ? getAbsoluteURLPath(`${sourcePath}/${image}`) : `${sourcePath}/${image}`;
};

export const LOGO_PLACEHOLDER = process.env.REACT_APP_LOGO_PLACEHOLDER || process.env.NEXT_LOGO_PLACEHOLDER || '/assets/images/thumb-placeholder.png';
export const SERVICE_PLACEHOLDER = process.env.REACT_APP_SERVICE_PLACEHOLDER || process.env.NEXT_SERVICE_PLACEHOLDER || '/assets/images/services_placeholder.png';

export const getFileNameFromSource = (value, defaultValue) => {
  try {
    if (!value) return defaultValue || '----';
    const elements = value?.split('/');
    return elements[elements?.length - 1] || defaultValue || '----';
  } catch (e) {
    return defaultValue || '----';
  }
};
