import {useEffect, useMemo} from 'react';
import {ApiClientService} from '@dofleini/security';
import {authentication} from '@/settings';
import {useQuery, useQueryClient} from 'react-query';
import cookie from 'react-cookies';
import {setAuthData} from '@/contexts/SessionControlContext';
import {ERRORS} from '@/settings/errors';
import {handleResponse} from '@/utils/requests';
import {logout} from '@/modules/authentication/services/AuthClient';

const USER_PROFILE = '__user_profile__';

export const useMe = () => {
  const queryClient = useQueryClient();
  const path = `${authentication.path}/api/admin/me`;

  const user = useMemo(() => {
    const token = cookie.load(ApiClientService.ACCESS_TOKKEN_KEY);
    if (token) {
      ApiClientService.setToken(token);
      if (!ApiClientService.getSpace())
        ApiClientService.setSpace('PUBLIC');
    } else {
      ApiClientService.removeSpace();
      ApiClientService.setToken(null);
      localStorage.removeItem(ApiClientService.ACCESS_TOKKEN_KEY);
      localStorage.removeItem(USER_PROFILE);
      return null;
    }
    const user = localStorage.getItem(USER_PROFILE);
    if (user)
      try {
        return JSON.parse(user);
      } catch (e) {
        //ignoring
      }
  }, []);

  const {isLoading, error, data, refetch, isStale} = useQuery('user-me', async () => {
    if (!ApiClientService.getToken()) return Promise.reject({authenticatedError: true});
    let data = null;
    try {
      data = (await handleResponse(ApiClientService.get(path, {headers: {admin: true}})));
    } catch (e) {
      if (e?.reference === ERRORS.NOT_ADMIN) {
        data = {
          user: e.data,
          auth: {
            accessToken: ApiClientService.getToken(),
            space: ApiClientService.getSpace()
          }
        };
      } else
        return Promise.reject(e);
    }
    const {user, auth} = data;
    setAuthData(auth);
    localStorage.setItem(USER_PROFILE, JSON.stringify(user));
    return user;
  }, {
    retry: 2, // Will retry failed requests 10 times before displaying an error
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  useEffect(() => {
    if (error) {
      console.log('useEffect', error);
      if (error?.reference === ERRORS.NOT_ADMIN) {
        console.log('ignoring');
        // ignoring
      } else if (error?.status === 401) {
        logout().then(() => {
          queryClient.invalidateQueries('user-me');
        });
      }
    }
  }, [queryClient, error]);

  return {
    data: error?.status || error?.authenticatedError ? undefined : data || user,
    isLoading, error, refetch, isStale
  };
};

