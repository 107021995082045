import React from 'react';
import {
  RiBriefcase4Line,
  RiCalendarEventLine,
  RiFileList3Line,
  RiHome3Line,
  RiMessage2Line,
  RiUserSettingsLine
} from 'react-icons/ri';
import {BiImages} from 'react-icons/bi';
import {AiOutlineNotification} from 'react-icons/ai';
import {MdOutlineLibraryAddCheck, MdPayment, MdOutlineListAlt} from 'react-icons/md';
import {FiMapPin} from 'react-icons/fi';

const iconsProps = {
  className: 'antd-icon w-6 h-6'
};

/**
 * Navigation define all the routes and link that
 * will be render in the sidebar or navbar(depending of the layout)
 *
 * It can be an
 * {Array} Use array when yo have group sections, like Main, Admin , etc
 * Ex: [{title:'name', menu:[...],}, ....]
 *
 * {Object} Use object when yo don't have section , it is just one,
 * Ex: {menu: [.....]}
 * */
export const navigation = {
  menu: [
    {
      title: 'home',
      exact: 'true',
      link: '/',
      icon: <RiHome3Line {...iconsProps}/>
    },
    {
      exact: 'true',
      link: '/services',
      title: '/services',
      permissions: ['TENDERS'],
      icon: <MdOutlineLibraryAddCheck {...iconsProps}/>
    },
    {
      exact: 'true',
      link: '/groupproducts',
      title: '/groupProduct',
      permissions: ['TENDERS'],
      icon: <MdOutlineListAlt {...iconsProps}/>
    },
    {
      exact: 'true',
      link: '/biddings',
      permissions: ['TENDERS'],
      icon: <RiFileList3Line {...iconsProps}/>
    },
    {
      exact: 'true',
      title: '/job-offers',
      link: '/job-offers',
      permissions: ['JOBS'],
      icon: <RiBriefcase4Line {...iconsProps}/>
    },
    {
      exact: 'true',
      link: '/events',
      permissions: ['EVENTS'],
      disabled: true,
      icon: <RiCalendarEventLine  {...iconsProps}/>
    },
    {
      exact: 'true',
      title: '/gallery',
      link: '/gallery',
      permissions: ['IMAGES', 'GALLERY'],
      icon: <BiImages  {...iconsProps}/>
    },
    {
      link: '/contacts',
      permissions: ['CONTACT'],
      disabled: true,
      icon: <RiMessage2Line  {...iconsProps}/>
    },
    {
      link: '/locations',
      permissions: ['LOCATIONS'],
      disabled: true,
      icon: <FiMapPin  {...iconsProps}/>
    },
    {
      divider: true,
    },
    {
      link: '/ads',
      permissions: ['ADS'],
      disabled: true,
      icon: <AiOutlineNotification  {...iconsProps}/>
    },
    {
      divider: true,
    },
    {
      link: '/admin/users',
      icon: <RiUserSettingsLine {...iconsProps}/>,
      permissions: ['USER.READ']
    },
    {
      link: '/billing',
      disabled: true,
      icon: <MdPayment {...iconsProps}/>,
      permissions: ['USER.READ']
    }
  ]
};
