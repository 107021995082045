import React from 'react';
import {UserFilterStatus, ValueUserState} from '@/modules/admin/components/UserFilterStatus';

export const filters = [
  {
    name: 'user.filters.status',
    // eslint-disable-next-line react/prop-types,react/display-name
    render: ({filter, t, applyFilter}) => <UserFilterStatus filter={filter} t={t} applyFilter={applyFilter}/>,
    // eslint-disable-next-line react/prop-types,react/display-name
    renderValue: ({filterValue, t, filter}) => <ValueUserState filter={filter} t={t} filterValue={filterValue}/>,
  },
  {
    name: 'user.filters.createdAt',
    field: 'createdAt',
    type: 'dateRange',
  },
  //todo enum example
  // {
  //   name: 'status',
  //   type: 'enum',
  //   field: 'status',
  //   options: [
  //     {label: 'user.status.UNVERIFIED', value: 'UNVERIFIED'},
  //     {label: 'user.status.UNVERIFIED_HELP', value: 'UNVERIFIED_HELP'},
  //     {label: 'user.status.LOCK', value: 'LOCK'},
  //     {label: 'user.status.LOCK_HELP', value: 'LOCK_HELP'},
  //     {label: 'user.status.ACTIVE', value: 'ACTIVE'},
  //     {label: 'user.status.ACTIVE_HELP', value: 'ACTIVE_HELP'},
  //     {label: 'user.status.DELETED', value: 'DELETED'},
  //     {label: 'user.status.DELETED_HELP', value: 'DELETED_HELP'},
  //   ]
  // },
  //todo number example
  // {
  //   name: 'number',
  //   field: 'fieldNumber',
  //   type: 'number',
  //   min: 0,
  //   max: 99999
  // },
  // {
  //   name: 'boolType',
  //   field: 'fieldBool',
  //   type: 'bool',
  //   trueText: 'filter.trueText',
  //   falseText: 'filter.falseText',
  // },
  // {
  //   name: 'remoteData',
  //   field: 'userField',
  //   type: 'dynamic',
  //   request: '/api/users/search',
  //   labelKey: 'fullName',
  // }
];
