export const USER_STATUS_APPEARANCE = {
  ACTIVE: {color: 'green'},
  UNVERIFIED: {color: 'orange'},
  LOCK: {color: 'red'},
  DELETED: {color: '#f50'},
};

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  UNVERIFIED: 'UNVERIFIED',
  LOCK: 'LOCK',
  DELETED: 'DELETED',
};
