import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Button, Space} from 'antd';

const Card = styled.div`
  border-radius: 5px;
`;

const guia = process.env.REACT_APP_GUIA_URL || 'http://localhost:3000';
const contact = process.env.REACT_APP_CONTACT_SUPPORT || 'soporte@dofleinisoftware.com';

const OwnerCard = ({title, subText, text, signUp, goTo, signIn}) => {
  return (
    <Card
      className={'text-center shadow-xl bg-blue-dark text-white flex flex-col items-center justify-center mt-8 py-8 px-4 md:px-8'}>
      <h1 className={'mb-6 max-w-90 text-white leading-7 uppercase text-center'}>
        {title}
      </h1>
      <p className={'mb-6 max-w-160'}>
        {text}
      </p>
      <p className={'mb-6 max-w-160'}>
        {subText}
      </p>
      <Space>
        {goTo && <a href={`${guia}/directorio-de-empresas`}>
          <Button type="primary">
            Ir a Guía de negocios
          </Button>
        </a>
        }
        {signIn && <a href={`${guia}/registrarse`}>
          <Button type="primary">
            Autenticarme
          </Button>
        </a>
        }
        {signUp && <a href={`${guia}/auth/registrarse`}>
          <Button>
            Crear cuenta
          </Button>
        </a>
        }
      </Space>
    </Card>
  );

};

export default memo(OwnerCard);

OwnerCard.propTypes = {
  goTo: PropTypes.bool,
  signIn: PropTypes.bool,
  signUp: PropTypes.bool,
  subText: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.string
};

OwnerCard.defaultProps = {
  goTo: true,
  subText: <div>Si su negocio no está en la guía. Puede escribir a <a
    href={`mailto:${contact}`}>{contact}</a> . <br/> Pronto podrá registrar nuevos negocios directamente!</div>,
  text: <div>Por favor diríjase a la <a className='text-warning' href={`${guia}/directorio-de-empresas`}>Guía de negocios</a>, busque su negocio
    y
    realice la reclamación de este.
    Una vez aceptado, usted podrá administrar su información</div>,
  title: 'Usted no tiene ningún negocio asociado'
};
