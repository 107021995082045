import React, {memo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {useDetailsContext} from '@/contexts/DetailsContext';

const OpenDetail = ({dialog}) => {
  const {closeDialog, openDialog} = useDetailsContext(dialog);
  const params = useParams();
  useEffect(() => {
    openDialog(params);
    return () => {
      closeDialog();
    };
  }, [closeDialog, openDialog, params]);
  return <></>;
};

export default memo(OpenDetail);

OpenDetail.propTypes = {
  dialog: PropTypes.string
};
