import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import classNames from 'classnames';

const FilterContent = ({t, className, children, actions}) => {

  return (<div className={classNames('w-full', className)}>
    {children}
    <div className="flex w-full p-2">
      {actions && <div className="pr-2">{actions}</div>}
      <Button htmlType="submit" type="primary" block>{t('confirm')}</Button>
    </div>
  </div>);
};

FilterContent.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  actions: PropTypes.any,
  t: PropTypes.func,
};

export default memo(FilterContent);