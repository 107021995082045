import React, {memo, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import RecoverPasswordForm from './RecoverPasswordForm';
import PageLayout from '@/modules/authentication/components/PageLayout';
import {Trans, useTranslation} from 'react-i18next';
import ForgotPassword from '@/modules/authentication/services/Frogot.api.service';
import {Button} from 'antd';
import QuestionLink from '@/modules/authentication/components/QuestionLink';

const pageInfo = {
  default: {
    title: 'recovery.title',
    subtext: 'recovery.subtext',
  },
  sent: {
    title: 'recovery.sentTitle',
    subtext: 'recovery.sentSubtext',
  }
};

function RecoverPassword() {
  const {t} = useTranslation('authentication');
  const [sent, setSent] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback((formData) => {

    setLoading(true);
    setError('');
    ForgotPassword.init(formData).then(() => {
      setLoading(false);
      setSent(formData.identifier);
    }).catch((err) => {
      setLoading(false);
      setError(err?.response?.data?.message || 'Network error');
    });
  }, []);


  const pageProps = sent ? pageInfo.sent : pageInfo.default;
  if (sent)
    pageProps.subtext = <Trans i18nKey="authentication:recovery.sentSubtext" values={{email: sent}}
      components={{brake: <p className={'mb-4'}/>, bold: <strong/>}}/>;


  return (
    <PageLayout {...pageProps} error={error}>
      {!sent ?
        <RecoverPasswordForm onSent={handleSubmit} loading={loading}/>
        :
        <Button loading={loading} disabled={loading} className={'w-full mb-6'}
          size={'large'}
          onClick={() => setSent('')}
          type={'primary'}
        >
          {t('recovery.newLink')}
        </Button>
      }
      <QuestionLink link={'/signin'} linkText={t('login.title')} question={t('recovery.need')}/>
    </PageLayout>
  );
}

export default memo(RecoverPassword);

RecoverPassword.propTypes = {
  onSend: PropTypes.func
};
