import generatePassword from 'omgopass';

export const generate = () => {
  return generatePassword({
    minSyllableLength: 5,
    hasNumbers: true,
    titlecased: false,
    separators: ''
  });
};
