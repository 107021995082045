// import PropTypes from 'prop-types';
import React, {createContext, useCallback, useContext, useState} from 'react';
import {useFilter} from '@/contexts/FilterContext';


export const createContextList = (usePagination, name, pageConfig) => {
  const Context = createContext();

  function Provider(props) {
    const {search, filter} = useFilter();
    const [openModal, setOpen] = useState({open: false});

    const onOpenModal = useCallback((payload) => {
      setOpen({open: true, payload});
    }, []);

    const onCancelModal = useCallback(() => {
      setOpen({open: false});
    }, []);

    const {
      page, data, totalPages, setPage, isFetching, activeKey, setSize, size
    } = usePagination(search, filter, pageConfig);


    return (
      <Context.Provider value={{
        page, data, totalPages, setPage, isFetching, activeKey, openModal, onOpenModal, onCancelModal, setSize, size
      }} {...props} />
    );
  }

  function useList() {
    const context = useContext(Context);
    if (context === undefined) {
      throw new Error(`use${name} must be used within a ${name}Provider`);
    }
    const {
      page, data, totalPages, setPage, isFetching, activeKey, openModal, onOpenModal, onCancelModal, setSize, size
    } = context;

    return {
      page, data, totalPages, setPage, isFetching, activeKey, openModal, onOpenModal, onCancelModal, setSize, size
    };
  }

  Provider.propTypes = {};


  return {Provider, useList};
};
