import numeral from 'numeral';

export const formatNumber = (price) => {
  return numeral(price).format('0,0.00');
};

export const showPrice = (price, currency='$') => {
  return price ? `${currency}${formatNumber(price)}` : '-';
};

export const scrollToPosition = (value = 0) => {
  document.body.scrollTop = value; // For Safari
  document.documentElement.scrollTop = value;
};

export const getFileName = (value) => {
  if (!value) return '';
  const paths = value.split('/');
  return paths[paths.length - 1];
};

export const showCard = (value) => {
  return '**** **** **** ' + value.substr(-4);
};
