import React, {memo} from 'react';
import OwnerCard from '@/components/OwnerCard';
import WelcomeLeftSidebar from '@/components/WelcomeLeftSidebar';
import FullLayout from '@/modules/authentication/components/Layout/FullLayout';

const guia = process.env.REACT_APP_GUIA_URL || 'http://localhost:3000';


const text = <div>
  Por favor diríjase a la <a className='text-warning' href={`${guia}/directorio-de-empresas`}>Guía de negocios</a> y acceda con su correo y
  contraseña.<br/>
  Si aún no tiene una cuenta, puede crearse una.
</div>;


const NoBusiness = () => {
  return (
    <FullLayout className={'w-full'}>
      <WelcomeLeftSidebar>
        <OwnerCard title={'Debe estar autenticado para acceder a este servicio'}
          text={text} subText={''}
          signUp
          signIn={false}
          goTo={true}
        />
      </WelcomeLeftSidebar>
    </FullLayout>
  );
};

export default memo(NoBusiness);
