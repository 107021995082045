import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Authorization from '@/modules/authentication/services/Auth.api.service';
import {useAuth} from '@dofleini/security';
import PageLayout from '@/modules/authentication/components/PageLayout';
import RecoverPasswordForm from '@/modules/authentication/pages/Recovery/RecoverPasswordForm';
import {useTranslation} from 'react-i18next';
import QuestionLink from '@/modules/authentication/components/QuestionLink';
import {message, Skeleton} from 'antd';

const pageInfo = {
  default: {
    title: 'confirmation.successTitle',
    subtext: 'confirmation.successSubtext',
  },
  loading: {
    title: 'confirmation.loading',
    subtext: '',
  },
  sent: {
    title: 'confirmation.newTitle',
    subtext: 'recovery.newSentSubtext',
  },

  error: {
    title: 'confirmation.errorCheckTitle',
    titleProps: {className: 'text-danger'},
    subtext: 'confirmation.errorCheckSubtext',
  }
};

const executed = {};

function EmailConfirmation(props) {
  const {t} = useTranslation('authentication');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [errorCheck, setCheckError] = useState(false);
  const [sent, setSent] = useState(false);
  const {reload} = useAuth();

  useEffect(() => {
    if (!executed[props.match.params.key]) {
      executed[props.match.params.key] = true;
      Authorization.checkConfirmationAccess(props.match.params.key).then(res => {
        setCheckError(false);
        setLoading(false);
        Authorization.service.setToken(res.accessToken, true);
        if (res.space)
          Authorization.service.setSpace(res.space);
        setTimeout(() => {
          reload();
        }, 2000);
      }).catch(() => {
        setCheckError(true);
        setLoading(false);
      });
    }
  }, [props.match.params.key, reload]);

  const handleSubmit = useCallback((formData) => {
    setLoading(true);
    Authorization.resendConfirmationAccess(formData).then(() => {
      setLoading(false);
      message.success(t('signup.resendSuccess'));
      setSent(formData.identifier);
    }).catch((err) => {
      setLoading(false);
      const notFound = err?.response?.data?.message === 'User not found or The user is already verified' ? 'confirmation.notfound' : '';
      setError(notFound || (err && (err.status === 401 ? 'signup.badCredentials' : !err.status ? 'login.network' : 'login.generalError')));
    });
  }, [t]);

  let pageProps = loading ? pageInfo.loading : (errorCheck ? pageInfo.error : pageInfo.default);

  let content = errorCheck ?
    <RecoverPasswordForm label={t('confirmation.newLink')} action={t('confirmation.action')} onSent={handleSubmit}
      loading={loading}/> : '';

  if (sent) {
    content = '';
    pageProps = pageInfo.sent;
  }

  return (
    <PageLayout {...pageProps} error={error}>
      <Skeleton loading={loading} paragraph={true}>
        {content}
        <QuestionLink link={'/signin'} linkText={t('login.title')} question={t('signup.haveAccount')}/>
      </Skeleton>
    </PageLayout>
  );
}

export default EmailConfirmation;

EmailConfirmation.propTypes = {
  match: PropTypes.any
};
