import React, {memo, Suspense} from 'react';
import PageLoader from '@/components/PageLoader';
import Onboard from '@/modules/onboardingBusiness/Onboard';
import {useSpace} from '@dfl/space';

const OnboardBusinessModule = () => {
  const {isLoading} = useSpace();
  if (isLoading)
    return <PageLoader/>;
  return (
    <Suspense fallback={<PageLoader/>}>
      <Onboard/>
    </Suspense>
  );

};

export default memo(OnboardBusinessModule);
