/* eslint-disable no-undef */
import {ApiClientService} from '@dofleini/security';
import {authentication} from '@/settings';

const USER_PROFILE = process.env.REACT_APP_USER_PROFILE || '__user_profile__';

export const setProfile = (profile) => {
  sessionStorage.setItem(USER_PROFILE, profile);
};

export const getProfile = () => {
  sessionStorage.getItem(USER_PROFILE);
};

class Authorization {

  constructor() {

    this.service = ApiClientService;

  }

  signUp = (params) => {
    return this.service.post(`${authentication.path}/api/signup`, params);
  };

  requestAccess = (params) => {
    return this.service.post(`${authentication.path}/api/access-requests`, params);
  };

  processAccess = (key, accept) => {
    return this.service.post(`${authentication.path}/api/process-access`, {key, accept});
  };

  signIn = (params) => {
    return this.service.post(`${authentication.path}/api/signin`, params);
  };


  checkConfirmationAccess = (key) => {
    return this.service.post(`${authentication.path}/api/confirm`, {key})
      .then(r => r.data);
  };

  invitationAccess = (key) => {
    return this.service.post(`${authentication.path}/api/accept-invite`, {key})
      .then(r => r.data);
  };

  resendConfirmationAccess = (data) => {
    return this.service.post(`${authentication.path}/api/confirm/resend`, data)
      .then(r => r.data);
  };

  resendConfirmationAccessFromStorage = (identifier) => {
    return this.service.post(`${authentication.path}/api/confirm/resend`, {identifier})
      .then(r => r.data);
  };

  checkConfirmationMessage = (key) => {
    const phone = getProfile().phone;
    return this.service.post(`${authentication.path}/api/confirm-phone`, {key, phone}).then(r => r.data);
  };

  resendPhoneAccess = () => {
    console.log('send message to:', getProfile().phone);
  };

}

export default new Authorization();
