import styled from 'styled-components';
import banner from '@/assets/images/auth-image.png';

const FullLayout = styled.div`
  &:before {
    content: ' ';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${banner});
    background-size: cover;
    z-index: 0;
  }
`;

export default FullLayout;
