import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import { NameRuler, PasswordRuler } from '@dofleini/validator';
import QuestionLink from '@/modules/authentication/components/QuestionLink';
import PageLayout from '@/modules/authentication/components/PageLayout';
import { useSignUp } from '@/modules/authentication/hooks/useAuthentication';
import Password from '@/modules/authentication/components/Password';

function SignUpForm ({ onSent }) {
  const { t } = useTranslation('authentication');
  const { onSignUp, isLoading, error } = useSignUp(onSent);
  
  return (
    <PageLayout error={error} title={'signup.title'} subtext={'signup.subtext'}>
      <Form
        name="normal_login"
        layout={'vertical'}
        className="login-form"
        onFinish={onSignUp}
      >
        <Form.Item
          name="firstName"
          rules={[
            { required: true },
            NameRuler
          ]}
        >
          <Input
            disabled={isLoading}
            size={'large'}
            placeholder={t('name')}
          />
        </Form.Item>
        
        <Form.Item
          name="email"
          rules={[
            { required: true },
            { type: 'email' }
          ]}
        >
          <Input
            disabled={isLoading}
            size={'large'}
            placeholder={t('email')}
          />
        </Form.Item>
        
        <Form.Item
          name="password"
          help={'  '}
          rules={[{ required: true }, PasswordRuler]}
        >
          <Password
            lateral={false}
            disabled={isLoading}
            size={'large'}
            placeholder={t('password')}
          />
        </Form.Item>
        
        <Form.Item>
          {t('signup.accept')}<Link to={'/terminos-condiciones'}>{t('termAndConditions')}</Link>
        </Form.Item>
        
        <Form.Item>
          <Button
            size={'large'}
            disabled={isLoading}
            loading={isLoading}
            type="primary" htmlType="submit"
            className="login-form-button  w-full">
            {t('signup.action')}
          </Button>
        </Form.Item>
        <QuestionLink link={'/signin'} linkText={t('login.title')} question={t('signup.haveAccount')}/>
      </Form>
    </PageLayout>
  );
}

export default SignUpForm;

SignUpForm.propTypes = {
  onSent: PropTypes.func
};
