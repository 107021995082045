import {ApiClientService} from '@dofleini/security';
import {authentication} from '@/settings';
import {handleUserResponse} from '@/contexts/SessionControlContext';
import cookie from 'react-cookies';

const domain = process.env.REACT_APP_STORAGE_DOMAIN;

const cookiesCongif = () => {
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + 10);
  return {
    path: '/',
    expires: expires,
    domain: domain
  };
};

export function login(formData) {
  return ApiClientService.post(`${authentication.path}/api/signin`, formData).then(handleUserResponse);
}

export function register({username, password}) {
  return ApiClientService('register', {body: {username, password}}).then(handleUserResponse);
}

export function logout() {
  return new Promise((resolve) => {
    cookie.remove(ApiClientService.ACCESS_TOKKEN_KEY, cookiesCongif());
    window.sessionStorage.removeItem(ApiClientService.ACCESS_TOKKEN_KEY);
    window.localStorage.clear();
    resolve();
  });
}
