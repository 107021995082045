import React, {useMemo, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {Form, Select, InputNumber, Slider} from 'antd';
import FilterContent from '../../FilterContent';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

export const SELECT_SUBTYPES = {
  LESS_THAN: 'LESS_THAN',
  GREATER_THAN: 'GREATER_THAN',
  RANGE: 'RANGE',
  EQUAL: 'EQUAL',
};

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 99999999999999;

export const NumberComponent = ({filter, t, applyFilter}) => {
  const [selectedType, setSelectValue] = useState(SELECT_SUBTYPES.LESS_THAN);
  const [form] = Form.useForm();

  const [min, max] = useMemo(() => [filter.min || DEFAULT_MIN, filter.max || DEFAULT_MAX], [filter.max, filter.min]);

  const options = useMemo(() => map(SELECT_SUBTYPES, value => ({value, label: t(`numberFilter.${value}`)})), [t]);

  const onFinish = useCallback(({from, to, value}) => {
    let out = {field: filter.field};

    switch (selectedType) {
      case SELECT_SUBTYPES.EQUAL:
        out.type = 'TERM';
        out.value = value;
        break;
      case SELECT_SUBTYPES.RANGE:
        out.type = 'RANGE';
        out.from = from;
        out.to = to;
        break;
      case SELECT_SUBTYPES.LESS_THAN:
        out.type = 'RANGE';
        out.from = min;
        out.to = Number(value) - 1;
        break;
      case SELECT_SUBTYPES.GREATER_THAN:
        out.type = 'RANGE';
        out.from = Number(value) + 1;
        out.to = max;
        break;
      default:
        return;
    }
    applyFilter(out);
  }, [applyFilter, filter.field, max, min, selectedType]);

  return (<div className="w-56">
    <div className="p-2">
      <Select
        className="w-full" options={options} defaultActiveFirstOption value={selectedType}
        onChange={setSelectValue}/>
    </div>
    <Form layout="vertical"
      form={form} onFinish={onFinish} initialValues={{range: [min, max]}}
      onValuesChange={(value, values) => {
        if (!isNil(value?.range)) {
          const [from, to] = value?.range;
          if (from !== values?.from || to !== values?.to) {
            form.setFieldsValue({...values, from, to});
          }
          return;
        }
        const from = get(value, ['range', '0'], min);
        const to = get(value, ['range', '1'], max);
        if (!isNil(value?.from) && from !== value?.from) {
          form.setFieldsValue({...values, range: [value?.from, to]});
          return;
        }
        if (!isNil(value?.to) && to !== value?.to) {
          form.setFieldsValue({...values, range: [from, value?.to]});
        }
      }}>
      <FilterContent t={t}>
        <div className="flex py-2">
          {
            selectedType === SELECT_SUBTYPES.RANGE
              ? <div className="flex flex-col">
                <div className="p-2">
                  <Form.Item className="px-2 m-0 w-full" name="range">
                    <Slider
                      range min={min} max={max}
                    />
                  </Form.Item>
                </div>
                <div className="flex">
                  <Form.Item className="px-2 m-0 w-full" name="from" label={t('min')}>
                    <InputNumber min={min} className="w-full"/>
                  </Form.Item>
                  <Form.Item className="px-2 m-0 w-full" name="to" label={t('max')}>
                    <InputNumber max={max} className="w-full"/>
                  </Form.Item>
                </div>
              </div>
              : (
                <Form.Item className="px-2 m-0 w-full" name="value" valuePropName="defaultValue">
                  <InputNumber min={min} max={max} className="w-full"/>
                </Form.Item>
              )
          }
        </div>
      </FilterContent>
    </Form>
  </div>);
};

NumberComponent.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  applyFilter: PropTypes.func,
};

export const ValueRange = ({filterValue}) => {
  return useMemo(() => {
    if (!isNil(filterValue.value)) {
      return filterValue.value;
    } else if (!isNil(filterValue.from) && !isNil(filterValue.to)) {
      return `${filterValue.from} - ${filterValue.to}`;
    } else {
      return '';
    }
  }, [filterValue.from, filterValue.to, filterValue.value]);
};

ValueRange.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  filterValue: PropTypes.any,
};
