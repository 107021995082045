import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {BrowserRouter, Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {InternationalizationProvider} from '@/contexts/InternationalizationContext';
import {ResponsiveProvider} from '@/contexts/ResponsiveContext';
import {AppContextProvider} from '@/contexts/AppContext';

const history = createBrowserHistory();

const MainProvider = ({children}) => {

  return (
    <Router history={history}>
      <BrowserRouter>
        <ResponsiveProvider>
          <InternationalizationProvider>
            <AppContextProvider>
              {children}
            </AppContextProvider>
          </InternationalizationProvider>
        </ResponsiveProvider>
      </BrowserRouter>
    </Router>
  );
};

MainProvider.propTypes = {
  children: PropTypes.any,
  isMobile: PropTypes.bool
};

export default memo(MainProvider);
