/* eslint-disable */
import React, {useMemo} from 'react';
import MainApp from '@/modules/MainApp';
import {ApiClientService, useUser} from '@dofleini/security';
import UnauthenticatedApp from '@/modules/UnauthenticatedApp';
import OnboardingBusinessModule from '@/modules/onboardingBusiness';
import {SpaceProvider} from '@dfl/space';
import NoBusiness from "@/layout/NoBusiness";

const Space = ({user, children}) => {

  const config = useMemo(() => {
    return {
      enabled: !(!ApiClientService.getSpace() || ApiClientService.getSpace() === 'PUBLIC')
    };
  }, [user]);


  return (
    <SpaceProvider config={config}>
      {children}
    </SpaceProvider>
  );
};


function App() {
  const {user} = useUser();

  if (user && !user?.hasBusiness)
    return <NoBusiness/>

  if (user && !user?.onBoardingSpace)
    return <Space user={user}><OnboardingBusinessModule/></Space>

  if (user)
    return <Space user={user}><MainApp/></Space>;

  return <UnauthenticatedApp/>;
}

export default App;
