import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';
import filterFp from 'lodash/fp/filter';
import compose from 'lodash/fp/compose';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import {useFilter} from '@/contexts/FilterContext';

const TableFiltersContext = createContext();

function TableFiltersProvider({filters, ...props}) {
  const {setFilter} = useFilter();

  const [activeFilters, setActiveFilters] = useState([]);
  const [filtersValue, setFiltersValue] = useState([]);

  //Filter item logic
  const addActiveFilter = useCallback((f) => {
    setActiveFilters([f, ...activeFilters]);
  }, [activeFilters]);

  const onRemoveActiveFilter = useCallback(({name}) => {
    setActiveFilters(activeFilters.filter(f => f?.name !== name));
  }, [activeFilters]);

  const onClearAllActiveFilter = useCallback(() => {
    setActiveFilters([]);
  }, []);

  const inactiveFilters = useMemo(() => difference(filters, activeFilters, 'name'), [activeFilters, filters]);

  //Filter value logic
  const onChangeFilter = useCallback((_filter) => {
    setFiltersValue(uniqBy([_filter, ...filtersValue], 'name'));
  }, [filtersValue]);

  useEffect(() => {
    compose(
      setFiltersValue,
      filterFp(({name}) => find(activeFilters, ({name}))),
    )(filtersValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  useEffect(() => {
    if (filtersValue.length) {
      setFilter && setFilter({
        type: 'AND',
        filters: filtersValue.map(({value}) => value),
      });
    } else {
      setFilter && setFilter(null);
    }
  }, [filtersValue, setFilter]);

  return (
    <TableFiltersContext.Provider value={{
      filters,
      activeFilters,
      setActiveFilters,
      filtersValue,
      setFiltersValue,
      addActiveFilter,
      onRemoveActiveFilter,
      onClearAllActiveFilter,
      inactiveFilters,
      onChangeFilter,
    }} {...props} />
  );
}

function useTableFilters() {
  const context = useContext(TableFiltersContext);
  if (context === undefined) {
    return {};
  }
  const {
    filters,
    activeFilters,
    setActiveFilters,
    filtersValue,
    setFiltersValue,
    addActiveFilter,
    onRemoveActiveFilter,
    onClearAllActiveFilter,
    inactiveFilters,
    onChangeFilter,
  } = context;

  return {
    filters,
    activeFilters,
    setActiveFilters,
    filtersValue,
    setFiltersValue,
    addActiveFilter,
    onRemoveActiveFilter,
    onClearAllActiveFilter,
    inactiveFilters,
    onChangeFilter,
  };
}

TableFiltersProvider.propTypes = {
  filters: PropTypes.array,
};

TableFiltersProvider.defaultProps = {
  filters: [],
};


export {TableFiltersProvider, useTableFilters};
