import SpaceApiService from '@/modules/admin/services/SpaceApiService';
import {useQuery} from 'react-query';
import {useUser} from '@dofleini/security';

export const MY_SPACES_KEY = 'my-spaces';

export const useGetMySpaces = () => {
  const {user} = useUser();

  return useQuery([MY_SPACES_KEY, user?.id], SpaceApiService.getMySpaces, {
    enabled: !!user,
    retry: 3,
    refetchOnWindowFocus: false,
  });
};
