import React, { memo } from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import Email from '@/components/Email';
import { Link } from 'react-router-dom';
import { getFromSource } from '@/utils/getImages';
import { PhoneOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import UserStatus from '@/modules/admin/components/UserStatus';

const UserCell = ({ user }) => {
  return (
    <Link to={`/admin/users/${user?._id}`}>
      <div className={'flex flex-row'}>
        <div className={'flex lg:items-center mr-2 lg:mr-2 min-w-8'}>
          <Avatar src={getFromSource(user?.avatar)} icon={<UserOutlined/>}/>
        </div>
        <div className={'flex flex-col justify-center leading-3 truncate'}>
          <span className={'font-medium lg:font-normal text-lg lg:text-base text-black lg:text-primary'}>
            {user?.fullName}
          </span>
          <span className={'my-1 lg:hidden'}>
            <Email item={user}><MailOutlined/>{user?.email}</Email>
          </span>
          {user?.phone && <span className={'lg:hidden'}>
            <PhoneOutlined className={'my-1 mr-2'}/>{user?.phone}
          </span>}
          {<UserStatus status={user?.status} user={user} className={'md:hidden'}/>}
        </div>
      </div>
    </Link>
  );
};

UserCell.propTypes = {
  user: PropTypes.object,
};

export default memo(UserCell);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Email component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} user this is the row instance
 * */
export const renderUser = (text, user) => {
  return (
    <UserCell user={user}/>
  );
};

