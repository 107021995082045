import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Upload} from 'antd';
import {UploadOutlined, UserOutlined} from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import useUploadImage from '@/components/UploadImage/useUploadImage';
import {useTranslation} from 'react-i18next';
import style from './index.less';
import classNames from 'classnames';
import { getAbsoluteURLPath } from '@/settings/utils';

const UploadImage = ({onChange, value, disabled, btnProps, btnText, className, shape, icon}) => {
  const {t} = useTranslation('common');
  const {props, handleChange, imageUrl, loading} = useUploadImage(value, onChange);
  const Icon = icon || UserOutlined;
  return (<>
    <ImgCrop modalTitle={t('editImage')} modalCancel={t('cancel')} modalOk={t('ok')} rotate>
      <Upload
        disabled={disabled}
        name="avatar"
        listType="picture-card"
        className={classNames({[style.avatarUploader]: shape !== 'square'}, className)}
        showUploadList={false}
        {...props}
        onChange={handleChange}>
        <Avatar src={getAbsoluteURLPath(imageUrl, true)} icon={<Icon/>} size={90} shape={shape}/>
        <Button
          type="default"
          className={style.buttonUploader}
          loading={loading}
          icon={<UploadOutlined/>}
          {...btnProps}
        >
          {loading ? t('loading') : t(btnText)}
        </Button>
      </Upload>
    </ImgCrop>
  </>
  );
};

export default memo(UploadImage);

UploadImage.defaultProps = {
  value: '',
  btnProps: {},
  btnText: 'explore',
};

UploadImage.propTypes = {
  button: PropTypes.bool,
  shape: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  icon: PropTypes.any,
  className: PropTypes.string,
  btnProps: PropTypes.object,
  btnText: PropTypes.string,
};

