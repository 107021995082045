import React from 'react';
import {Route} from 'react-router-dom';
import Authorization from '@/modules/authentication/services/Auth.api.service';

const handleAuthentication = props => {
  if (/access_token|id_token|error/.test(props.location.hash)) {
    Authorization.auth0.popup.callback({hash: props.location.hash});
  }
};


const SocialRoute = (props) => {

  return (
    <Route {...props}
      render={props => {
        handleAuthentication(props);
        return (
          <div
            style={{
              height: '600pc',
              width: '500px',
              backgroundColor: 'white',
              position: 'fixed',
              zIndex: 10000
            }}
          />
        );
      }}
    />
  );
};

SocialRoute.propTypes = {};

SocialRoute.defaultProps = {};

export default SocialRoute;
