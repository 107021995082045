import React, {memo} from 'react';
import HorizontalLayout from '@/layout/Horizontal';
import PropTypes from 'prop-types';

const AppLayout = ({children, ...props}) => {
  let Layout = HorizontalLayout;
  return (
    <Layout {...props}>
      {children}
    </Layout>
  );
};

export default memo(AppLayout);

AppLayout.propTypes = {
  children: PropTypes.any
};
