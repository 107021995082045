import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Dropdown, Menu } from 'antd';
import map from 'lodash/map';
import { DownOutlined } from '@ant-design/icons';
import style from './index.less';
import { useTranslation } from 'react-i18next';
import { useSpace } from '@dfl/space';
import classNames from 'classnames';
import { getFromSource, LOGO_PLACEHOLDER } from '@/utils/getImages';
import { ApiClientService, useAuth } from '@dofleini/security';

const renderItem = ({ logo, business }, mini) => {
  return (
    <div className="flex items-center mr-2 whitespace-normal">
      <div className={classNames('w-min', mini ? 'ml-2' : 'pr-1')}>
        <Avatar shape={'square'} src={getFromSource(logo || business?.logo) || LOGO_PLACEHOLDER}
          className={'border border-gray-300 border-solid'}
          size={mini ? 'small' : 'default'}/>
      </div>
      {!mini && (
        <div className="pl-2">
          <span className="font-semibold">{business?.nameReal}</span>
        </div>
      )}
    </div>
  );
};

const SpaceSwitcherList = ({ spaces, mini }) => {
  const { t } = useTranslation('profile');
  const { space: activeSpace, refetch } = useSpace();
  const { reload } = useAuth();

  const handleChange = useCallback(
    async ({ key }) => {
      ApiClientService.setSpace(key);
      await reload();
      refetch();
      // await changeSpace(key);
    },
    [refetch, reload]
  );

  const menu = useCallback(() => {
    return <Menu className={style.menu} onClick={handleChange}>
      <Menu.ItemGroup title={t('changeSpace')}>
        {map(spaces, (space) => (
          <Menu.Item key={space?.identifier}>{renderItem(space)}</Menu.Item>
        ))}
      </Menu.ItemGroup>
    </Menu>;
  }, [handleChange, spaces, t]);

  return (
    <>
      {!!activeSpace && (
        <Dropdown overlay={menu} trigger={['click']}>
          <div className="flex justify-between cursor-pointer pl-4">
            {renderItem(activeSpace, mini)}
            <div className="pr-4 flex items-center">
              <DownOutlined/>
            </div>
          </div>
        </Dropdown>
      )}
    </>
  );
};

const SpaceSwitcher = ({ spaces, mini }) => {
  const { space: activeSpace } = useSpace();
  if (spaces.length > 1)
    return <SpaceSwitcherList spaces={spaces} mini={mini}/>;

  return (
    <div className="flex justify-between cursor-pointer w-full">
      {renderItem(activeSpace, mini)}
    </div>
  );
};

export default memo(SpaceSwitcher);

SpaceSwitcher.propTypes = {
  mini: PropTypes.bool,
  spaces: PropTypes.array,
};

SpaceSwitcherList.propTypes = {
  mini: PropTypes.bool,
  spaces: PropTypes.array
};
