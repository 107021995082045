import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'antd';
import {useMenu} from './useMenu';
import styled from 'styled-components';


const MenuContainer = styled.div`
  background-color: white;
  min-width: 232px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  border-radius: 6px 6px;
  padding: 12px 16px;

  .ant-menu-item {
    padding: 5px 0px;
    line-height: 22px;
    height: auto;
    margin: 0 !important;
  }

  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
  }
`;

const Menu = ({children, options, start, menuProps, onClick, ...props}) => {


  const menu = useMenu(options, menuProps, onClick);
  const overlay = useMemo(() => {
    return <MenuContainer>
      {start}
      {menu}
    </MenuContainer>;
  }, [start, menu]);

  return <Dropdown overlay={overlay} trigger={['click']} {...props}>
    {children}
  </Dropdown>;

};

export default memo(Menu);

Menu.propTypes = {
  children: PropTypes.any,
  menuProps: PropTypes.object,
  onClick: PropTypes.func,
  options: PropTypes.array,
  start: PropTypes.any,
  trigger: PropTypes.array
};

Menu.defaultProps = {
  menuProps: {},
  trigger: ['click']
};
