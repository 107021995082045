import React from 'react';
import {RouteLoader} from '@dofleini/security';
import SocialRoute from '@/modules/authentication/components/socialRoute';
import routes from '@/modules/authentication/routes/routes.constant';


const ModuleRoutes = () => {
  return (
    <>
      <SocialRoute path="/callback"/>
      <RouteLoader routes={routes} notfoundRedirect={'/'}/>
    </>
  );
};

ModuleRoutes.propTypes = {};

ModuleRoutes.defaultProps = {};

export default ModuleRoutes;
