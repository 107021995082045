import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useAuth} from '@dofleini/security';
import {useDetailsContext} from '@/contexts/DetailsContext';
import {NAME} from '@/modules/admin/containers/Profile';

export const useUserOptionClick = () => {
  const {logout} = useAuth();
  const {push} = useHistory();
  const {openDialog} = useDetailsContext(NAME);

  return useCallback(({action, link}) => {
    if (link)
      push(link);

    switch (action) {
      case 'LOGOUT':
        logout();
        break;
      case 'PROFILE':
        openDialog();
        break;
      default:
        break;
    }
  }, [openDialog, logout, push]);
};
