import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Skeleton} from 'antd';
import {useGetMySpaces} from '@/hooks/useMySpaces';
import {useSpace} from '@dfl/space';
import SpaceSwitcher from '@/components/SpaceIndicator/SpaceSwitcher';
// import SpaceIndicator from '@/modules/admin/components/SpaceIndicator';

const SpaceIndicator = ({mini}) => {
  const {isLoading, data, error} = useGetMySpaces();
  const {isLoading: loadingCurrent, space} = useSpace();
  if (isLoading || loadingCurrent || !space || error) {
    return (
      <div>
        <Skeleton active avatar={{shape: 'circle'}} paragraph={false} title={!mini}/>
      </div>
    );
  }

  return <SpaceSwitcher spaces={data} mini={mini}/>;
};

export default memo(SpaceIndicator);

SpaceIndicator.propTypes = {
  mini: PropTypes.bool,
};
