import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Divider} from 'antd';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import style from './index.less';
import {ReactComponent as Logo} from '@/assets/images/logo.svg';
import {useResponsive} from '@/contexts/ResponsiveContext';

const PageLayout = ({title, subtext, titleProps, error, children}) => {
  const {t} = useTranslation('authentication');
  const subtextChild = typeof subtext === 'string' ? t(subtext) : subtext;

  const {isMobile} = useResponsive();

  return (
    <div className={'auth-content'}>
      {isMobile && (
        <div className="flex items-center justify-center mb-8">
          <Logo/>
        </div>
      )}
      <h1 {...titleProps} className={classNames(style.title, titleProps.className, 'text-primary')}>{t(title)}</h1>
      <div className={'mb-2'}>{subtextChild}</div>
      <div className={'form-container'}>
        {!!error &&
        <Alert
          message={t(error)} type="error" showIcon closable/>
        }
        <Divider/>
        {children}
      </div>
    </div>
  );
};

export default PageLayout;

PageLayout.defaultProps = {
  titleProps: {}
};
PageLayout.propTypes = {
  children: PropTypes.any,
  titleProps: PropTypes.object,
  error: PropTypes.string,
  title: PropTypes.string,
  subtext: PropTypes.any,
};
