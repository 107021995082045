import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Menu, Tooltip} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import {useTableFilters} from '../../contexts/TableFiltersContext';
import {useTranslation} from 'react-i18next';

const FilterArea = ({translation, children, ...props}) => {
  const {t} = useTranslation(translation);

  const {inactiveFilters, addActiveFilter} = useTableFilters();

  const menu = useMemo(() => (
    <Menu className="min-w-48">
      <Menu.Item disabled className="cursor-default">
        <div className="flex items-center justify-between">
          <h3>{t('addFilters')}</h3>
          <Tooltip placement="rightTop" title={(
            <div>
              <div className="font-semibold">{t('filterHelp.title')}</div>
              <p>{t('filterHelp.description')}</p>
            </div>
          )}>
            <QuestionCircleOutlined/>
          </Tooltip>
        </div>
      </Menu.Item>
      {inactiveFilters.map((filter) => (
        <Menu.Item key={filter.name} onClick={() => addActiveFilter(filter)}>
          {t(filter.name)}
        </Menu.Item>
      ))}
    </Menu>
  ), [addActiveFilter, inactiveFilters, t]);

  return <>
    {!isEmpty(inactiveFilters) && <Dropdown overlay={menu} trigger={['click']} arrow {...props}>
      {children}
    </Dropdown>}
  </>;
};

FilterArea.propTypes = {
  translation: PropTypes.string,
  children: PropTypes.any
};

export default memo(FilterArea);