import React from 'react';
import {EnumComponent, ValueEnum} from './EnumComponent';
import {DateRangeComponent, ValueDateRange} from './DateRangeComponent';
import {NumberComponent, ValueRange} from './NumberComponent';
import {BoolComponent, ValueBool} from './BoolComponent';
import {RemoteDataComponent, ValueRemoteData} from './RemoteDataComponent';

export const COMPONENT_BY_TYPE = {
  enum: EnumComponent,
  dateRange: DateRangeComponent,
  number: NumberComponent,
  bool: BoolComponent,
  dynamic: RemoteDataComponent,
};

const Empty = () => null;

export const createRenderByType = (type) => {
  const ComponentByType = COMPONENT_BY_TYPE[type] || Empty;

  // eslint-disable-next-line react/display-name
  return (props = {}) => (<ComponentByType {...props}/>);
};

// eslint-disable-next-line react/prop-types
const DefaultRender = ({filterValue}) => <div>{filterValue?.value}</div>;

export const createRenderValueByType = (type) => {
  let ComponentByType;
  switch (type) {
    case 'enum':
      ComponentByType = ValueEnum;
      break;
    case 'number':
      ComponentByType = ValueRange;
      break;
    case 'dateRange':
      ComponentByType = ValueDateRange;
      break;
    case 'bool':
      ComponentByType = ValueBool;
      break;
    case 'dynamic':
      ComponentByType = ValueRemoteData;
      break;
    default:
      ComponentByType = DefaultRender;
      break;
  }
  // eslint-disable-next-line react/display-name
  return (props = {}) => (<ComponentByType {...props}/>);
};