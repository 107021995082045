import {useCallback, useEffect, useState} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import { ApiClientService, useUser } from '@dofleini/security';

export const createPaginationHook = (name, service) => {
  let asyncFn;

  switch (typeof service) {
    case 'function':
      asyncFn = service;
      break;
    case 'string':
      asyncFn = params => ApiClientService.post(service, params);
      break;
    default:
      throw new TypeError('Invalid type of service');
  }

  return (search, filters, config = {}, queryConfig = {}) => {
    const queryClient = useQueryClient();
    const {user} = useUser();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(config.size || 10);
    const [sort, setSort] = useState(config.sort);

    const fetchData = useCallback(async (page = 1) => {
      const {data, headers} = await asyncFn({page, size, search, filters, sort});
      const totalPages = Number(headers['x-total-count']);
      const hasMore = data.length === size;
      return {data, totalPages, hasMore};
    }, [size, search, filters, sort]);

    useEffect(() => {
      setPage(1);
    }, [search, filters]);

    const {
      status,
      error,
      isFetching,
      isLoading,
      isError,
      refetch,
      data = {},
    } = useQuery([name, page, search, size, sort, filters, user?.space], () => fetchData(page), {
      ...queryConfig,
      keepPreviousData: true
    });

    useEffect(() => {
      if (data?.hasMore) {
        queryClient.prefetchQuery([name, page + 1, search, size, filters, user?.space], () => fetchData(page + 1));
      }

      if (!data?.data?.length && page > 1) {
        setPage(page - 1);
      }
    }, [page, size, search, filters, fetchData, queryClient, data, user]);


    return {
      page,
      status,
      ...data,
      error,
      isFetching,
      isLoading,
      isError,
      refetch,
      setPage,
      setSize,
      setSort,
      size,
      activeKey: [name, page, search, size, filters]
    };
    // Prefetch the next page!
  };
};
