import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {useTranslation} from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import FilterList from '../FilterList';
import {useTableFilters} from '../../contexts/TableFiltersContext';

const FilterArea = ({translation}) => {
  const {t} = useTranslation(translation);
  const {activeFilters, onClearAllActiveFilter} = useTableFilters();

  return <div className="lg:px-2 py-2 lg:py-0 flex flex-col lg:flex-row flex-grow lg:items-center">
    <FilterList t={t}/>
    <div className="flex justify-between py-2 lg:py-0">
      <div>
        {!isEmpty(activeFilters) && <Button type="text" onClick={onClearAllActiveFilter}>{t('clearAll')}</Button>}
      </div>
    </div>
  </div>;
};

FilterArea.propTypes = {
  translation: PropTypes.string,
};

export default memo(FilterArea);
