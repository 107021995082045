import {EmailConfirmation, Login} from '@/modules/authentication/pages';
import UnauthenticatedApp from '@/modules/authentication/layout/UnauthenticatedApp';

export default {
  Login: {
    exact: true,
    path: ['/', '/signin'],
    component: Login
  },

  Others: {
    path: '/',
    component: UnauthenticatedApp
  },
};

export const authUtilRotes = {
  EmailConfirmation: {
    exact: true,
    path: '/verify/:key',
    component: EmailConfirmation
  },

  // RecoveryPassword: {
  //   exact: true,
  //   path: '/recovery_password',
  //   component: RecoverPassword
  // },
  // NewPassword: {
  //   exact: true,
  //   path: '/reset_password/:key',
  //   component: NewPassword
  // }
};
