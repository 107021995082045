import {useMutation, useQueryClient} from 'react-query';
import {message, Modal} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from 'react';
import {useTranslation} from 'react-i18next';
import useAlerts from '@/hooks/useAlerts';

export function showConfirm(t, description, onOk) {
  Modal.confirm({
    title: t('deleteConfirmation'),
    icon: <ExclamationCircleOutlined/>,
    content: description,
    onOk
  });
}

const useCrud = (service, keyList, keyOne) => {
  const {t} = useTranslation('common');
  const queryClient = useQueryClient();
  
  const {putErrorCode} = useAlerts();
  
  const {mutateAsync: create, isLoading: isCreating} = useMutation(service.save,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keyList);
        message.success(t('createSuccess'));
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });
    
  const {mutateAsync: update, isLoading: isUpdating} = useMutation(service.update,
    {
      onSuccess: ({data}) => {
        message.success(t('updateSuccess'));
        queryClient.invalidateQueries(keyList);
        queryClient.invalidateQueries(keyOne);
        queryClient.invalidateQueries(data?._id);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });
    
  const {mutateAsync: remove, isLoading: isRemoving} = useMutation(service.delete,
    {
      onSuccess: () => {
        message.success(t('deleteSuccess'));
        queryClient.invalidateQueries(keyList);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    }
  );
  
  return {
    create,
    isCreating,
    update,
    isUpdating,
    remove,
    isRemoving,
    isLoading: isCreating || isUpdating || isRemoving
  };
};

export default useCrud;
