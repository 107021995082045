import {ApiClientService, EntityApiService} from '@dofleini/security';
import {authentication} from '@/settings';

class UserApiService extends EntityApiService {

  updateStatus = (params) => {
    return ApiClientService.post(this.getPath('/business/status'), params);
  };

  update= (id, params, config) => {
    if (typeof id === 'object') {
      params = id;
      id = id._id;
    }

    return ApiClientService.patch(this.getPath('/business/' + id, params), params, config);
  };

  passwordReset = (params) => {
    return ApiClientService.post(this.getPath('/business/password-reset'), params);
  };

}

export default new UserApiService(`${authentication.path}/api/users`);
