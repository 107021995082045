import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {AiOutlineExport, AiOutlineUser} from 'react-icons/ai';
import UserAvatar from '../UserAvatar';
import Menu from '@/components/Menu';
import {useUser} from '@dofleini/security';

const UserDetails = ({user}) => {
  return <div className={'mb-2'}>
    <span className="ant-typography block">
      <strong>
        {user.fullName}
      </strong>
    </span>
    <span className="ant-typography opacity-75 block">
      {user.email}
    </span>
  </div>;
};

const UserDropdown = ({children, options, onClick}) => {
  const {user} = useUser();

  return (
    <Menu options={options} menuProps={{selectable: false}} onClick={onClick}
      start={<UserDetails user={user}/>}
      placement="bottomRight">
      <div className={'cursor-pointer'}>
        {children}
      </div>
    </Menu>
  );
};

export default memo(UserDropdown);

UserDropdown.propTypes = {
  children: PropTypes.any,
  layout: PropTypes.any,
  onClick: PropTypes.func,
  options: PropTypes.array,
  register: PropTypes.bool,
  requestAccess: PropTypes.bool
};

UserDropdown.defaultProps = {
  children: (<UserAvatar/>),
  options: [
    {
      divider: true
    },
    {
      title: 'profile',
      link: '/profile',
      icon: <AiOutlineUser/>,
    },
    {
      divider: true,

    },
    {
      className: 'mt-2',
      title: 'logout',
      action: 'LOGOUT',
      icon: <AiOutlineExport/>,
    }
  ],
  register: true,
  useCaptcha: false,
  requestAccess: true
};

UserDetails.propTypes = {
  user: PropTypes.object
};
