import {useLocation} from 'react-router-dom';
import {useCallback, useEffect, useState} from 'react';

const getPathTyes = (pathname) => {
  const isEditing = pathname.indexOf('edit') !== -1;
  const isCreating = pathname.indexOf('create') !== -1;
  const viewMode = !(isEditing || isCreating);

  return {
    isEditing,
    isCreating,
    viewMode

  };
};

export const useFormTitle = () => {
  const {pathname} = useLocation();
  const [{isEditing, isCreating, viewMode, backToView}, setState] = useState(getPathTyes(pathname));
  const toggleView = useCallback(() => {
    setState(prevState => {
      if (prevState.isEditing) {
        return {isEditing: false, viewMode: true};
      } else
        return {isEditing: true, viewMode: false, backToView: true};
    });
  }, []);

  useEffect(() => {
    setState(getPathTyes(pathname));
  }, [pathname]);

  let title = isCreating ? 'create' : 'details';
  if (isEditing)
    title = 'edit';
  return {
    title,
    isEditing,
    isCreating,
    pathname,
    viewMode,
    toggleView,
    backToView
  };
};
