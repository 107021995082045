import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {AppProviders} from '@dofleini/security';
import {useMe} from '@/modules/authentication/hooks/useUsers';
import * as AuthClient from '@/modules/authentication/services/AuthClient';
import {SessionControlProvider} from '@/contexts/SessionControlContext';

const SecurityProvider = ({children}) => {
  const {data, isLoading, refetch, isStale} = useMe();

  return (
    <AppProviders user={data} isLoading={!data && isLoading} authClient={AuthClient} reload={refetch} isSettled={isStale}>
      <SessionControlProvider>
        {children}
      </SessionControlProvider>
    </AppProviders>
  );
};

export default memo(SecurityProvider);

SecurityProvider.propTypes = {
  children: PropTypes.any
};
