// import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

const getStyle = ({collapsed, width, collapsedWidth}) => {
  let size = width;
  if (collapsed) {
    size = collapsedWidth;
  }
  return css`
    flex: 0 0 ${size}px;
    max-width: ${size}px;
    min-width: ${size}px;
    width: ${size}px;
  `;

};

const ResponsiveSider = styled.div`
  transition: all 0.2s;
  top: 50px;
  ${getStyle}
`;

export default ResponsiveSider;
