import React, {memo, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import useNavigation from '@/hooks/useNavigation';
import NavMenu from '@/layout/Menu/index';
import NavigationGroup from '@/layout/NavigationGroup/index';
import forEach from 'lodash/forEach';
import {useLocation} from 'react-router-dom';

function findActiveKeys(menuList, pathname) {
  let activeKeys = [];
  forEach(menuList, (item, index) => {
    if (item?.link && pathname.startsWith(item?.link)) {
      activeKeys.push(`link=>${item?.link}`);
    }
    if (item?.menu && item?.title) {
      const activeSubKeys = findActiveKeys(item?.menu, pathname);
      if (activeSubKeys.length) {
        activeKeys = [
          `${index}-${item.title}`,
          ...activeKeys,
          ...activeSubKeys
        ];
      }
    }
  });
  return activeKeys;
}

const NavigationMenu = ({theme, mode, disabled, collapsed, scrollRef}) => {
  const navigation = useNavigation();
  const {push} = useHistory();

  const {pathname} = useLocation();

  const openKeys = useMemo(() => findActiveKeys(navigation, pathname), [navigation, pathname]);

  const handleClick = useCallback(({link}) => {
    push(link);
  }, [push]);

  const baseMenuProps = useMemo(() => ({
    defaultSelectedKeys: openKeys,
    selectable: false
  }), [openKeys]);

  const menuProps = useMemo(() => ({
    ...baseMenuProps,
    openKeys,
  }), [baseMenuProps, openKeys]);

  const groupMenuProps = useMemo(() => {
    const menuProps = {...baseMenuProps};
    if (!collapsed){
      menuProps.defaultOpenKeys = openKeys;
    }
    return menuProps;
  }, [baseMenuProps, collapsed, openKeys]);

  if (Array.isArray(navigation)) {
    if (mode === 'horizontal')
      return <NavMenu
        key={pathname}
        subMenuProps={{disabled}}
        mode={mode} menu={navigation} theme={theme} onClick={handleClick}
        menuProps={menuProps}
        scrollRef={scrollRef}
        collapsed={collapsed}
        isFromSidebar
      />;

    return navigation.map((nav, key) => (
      <NavigationGroup
        subMenuProps={{disabled}}
        mode={mode} key={`${pathname}-${key}`} index={key} {...nav} theme={theme} onClick={handleClick}
        menuProps={groupMenuProps}
        scrollRef={scrollRef}
        collapsed={collapsed}
        isFromSidebar
      />
    ));
  }

  return (
    <NavMenu
      key={pathname}
      subMenuProps={{disabled}}
      mode={mode} menu={navigation.menu} theme={theme} onClick={handleClick}
      menuProps={baseMenuProps}
      scrollRef={scrollRef}
      collapsed={collapsed}
      isFromSidebar
    />
  );
};

NavigationMenu.propTypes = {
  mode: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
  disabled: PropTypes.bool,
  collapsed: PropTypes.bool,
  scrollRef: PropTypes.object,
};

NavigationGroup.propTypes = {
  mode: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
};

NavigationMenu.defaultProps = {
  mode: 'inline',
  theme: 'dark',
};

NavigationGroup.defaultProps = {
  mode: 'inline'
};

export default memo(NavigationMenu);
