import {ApiClientService, EntityApiService} from '@dofleini/security';
import {authentication} from '@/settings';

class UserEmailApiService extends EntityApiService {

  sendEmail = (params) => {
    return ApiClientService.post(this.getPath('/email'), params);
  };
}

export default new UserEmailApiService(`${authentication.path}/api/notifications`);
