export const ERRORS = {
  NETWORK_ERROR: '00000',
  UNAUTHORIZED_INVALID_CREDENTIALS: 'PJA000',
  UNAUTHORIZED_USER_NOT_VERIFIED: 'PJA001',
  UNAUTHORIZED_GLOBAL_CATEGORY: 'PJ0001',
  BAD_CATEGORY_TYPE_FOR_ROOT: 'PJ0002',
  UNAUTHORIZED_SPACE_CATEGORY: 'PJ0003',
  UNAUTHORIZED_MULTI_SPACE: 'PJ0004',
  INVALID_SPACE: 'PJ0005',
  DUPLICATED: 'PJ0006',
  BUSINESS_NIT_NOTFOUND: 'PJ0007',
  DUPLICATED_USER: 'PJ0008',
  NOTFOUND_OR_VERIFIED: 'PJ0009',
  PASSWORD_HISTORY: 'PJ0010',
  INVALID_DATA_PROVIDED: 'PJ0011',
  EXPIRED_TOKEN: 'PJ0012',
  INVALID_PASSWORD: 'PJ0013',
  DUPLICATE_KEY: 'E11000',
  NOT_ADMIN: 'PJ0014',
};
