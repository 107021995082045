import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input} from 'antd';
import {useTranslation} from 'react-i18next';

function RequireLink({label, action, loading, onSent}) {
  const {t} = useTranslation('authentication');

  return (
    <Form
      name="normal_login"
      layout={'vertical'}
      className="login-form"
      onFinish={onSent}
    >
      <Form.Item
        name="identifier"
        label={label}
        rules={[{required: true}, {type: 'email'}]}
      >
        <Input type={'email'} disabled={loading} placeholder={t('email')} size={'large'}/>
      </Form.Item>
      <Form.Item>
        <Button loading={loading} disabled={loading} className={'w-full'}
          size={'large'}
          htmlType={'submit'}
          type={'primary'}
        >
          {action || t('recovery.send')}
        </Button>
      </Form.Item>

    </Form>
  );
}

export default RequireLink;

RequireLink.propTypes = {
  action: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onSent: PropTypes.func
};
