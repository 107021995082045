import React, {memo} from 'react';
import {RiArrowDownSFill} from 'react-icons/ri';
import {useUserOptionClick} from '@/components/UserOptions/useUserOptionClick';
import {options} from '@/components/UserOptions/options';
import classNames from 'classnames';
import UserDropdown from '@/components/UserDropdown';
import UserAvatar from '@/components/UserAvatar';
import {useUser} from '@dofleini/security';

const UserOption = () => {
  const handleClick = useUserOptionClick();
  const {user} = useUser();

  return (
    <UserDropdown
      onClick={handleClick}
      options={options}>
      <div className={classNames('flex items-center ml-4')}>
        <UserAvatar user={user || {}} size={32} />
        <span className={'ml-2 hidden lg:block'}>{user.firstName}</span>
        <RiArrowDownSFill className={'hidden lg:block'}/>
      </div>
    </UserDropdown>
  );

};

export default memo(UserOption);
