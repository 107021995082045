import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import UserApiService from '@/modules/admin/services/UserApiService';
import {Button, message, Popconfirm} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';

const UserDeleteManyButton = ({users, onDelete}) => {
  const {t} = useTranslation('admin');
  const {mutateAsync, isLoading} = useMutation(UserApiService.deleteMany);
  const hasUsers = users && Array.isArray(users);
  const handleRemove = useCallback(async () => {
    if (hasUsers) {
      try {
        await mutateAsync(users);
        onDelete && onDelete();
      } catch (e) {
        message.error('Error');
      }
    }
  }, [hasUsers, mutateAsync, onDelete, users]);

  return (
    <Popconfirm
      placement="bottomRight"
      title={t(users?.length && users?.length > 1 ? 'manyDeleteConfirmation' : 'deleteConfirmation')}
      onConfirm={handleRemove}
      okText={t('delete')}
      cancelText={t('cancel')}
    >
      <Button size={'small'}
        disabled={isLoading || !hasUsers}
        type="link"
        danger
        icon={<DeleteOutlined/>}>
        {t('remove')}
      </Button>
    </Popconfirm>
  );

};

UserDeleteManyButton.propTypes = {
  onDelete: PropTypes.func,
  users: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

export default memo(UserDeleteManyButton);
