import React from 'react';
import PropTypes from 'prop-types';
import {ReactQueryDevtools} from 'react-query/devtools';
import SecurityProvider from '@/contexts/SecurityProvider';
import {DialogsContextProvider} from '@/contexts/DialogsContext';
import {DetailsContextProvider} from '@/contexts/DetailsContext';
import {MultiDialogsContextProvider} from '@/contexts/MultiDialogsContext';
import {QueryClientProvider, QueryClient, QueryCache, MutationCache} from 'react-query';

const queryCache = new QueryCache();
const mutationCache = new MutationCache();
const queryClient = new QueryClient({queryCache, mutationCache});

export const AppContextProvider = ({children}) => {

  return (
    <QueryClientProvider client={queryClient}>
      <SecurityProvider>
        <DialogsContextProvider>
          <MultiDialogsContextProvider>
            <DetailsContextProvider>
              {children}
            </DetailsContextProvider>
          </MultiDialogsContextProvider>
        </DialogsContextProvider>
        <ReactQueryDevtools position="bottom-right"/>
      </SecurityProvider>
    </QueryClientProvider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.object,
};
