import React, {Fragment} from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

export default (path) => {

  return (value) => {
    if (value === 'N/A' || isNil(value) || isEmpty(value)){
      return <Fragment/>;
    }

    if (path) {
      return get(value, path, <Fragment/>);
    }

    return value;
  };
};