import React, {useCallback, useMemo} from 'react';
import {Menu} from 'antd';
import {useTranslation} from 'react-i18next';

const {Item, SubMenu} = Menu;

export function renderMenu({menu, title, link, action, disabled, divider, ...props}, index, t) {
  const key = link ? `link=>${link}` : `action=>${action || index}`;
  const isLink = link?.startsWith('http');

  if (divider)
    return <Menu.Divider key={`${key}=>divider=>${index}`} {...props} />;

  if (menu)
    return (
      <SubMenu disabled={disabled}
        key={key}
        title={t(title)}
        {...props}
      >
        {menu.map((_item, _index) => renderMenu(_item, _index, t))}
      </SubMenu>
    );

  const content = isLink ? <a href={link} target={'_blank'} rel="noreferrer"> {t(title)}</a> : t(title);

  return (
    <Item disabled={disabled} key={key} {...props}>
      {content}
    </Item>
  );
}


export const useMenu = (options, menuProps, onClick) => {
  const {t} = useTranslation('authentication');

  const handleClick = useCallback((e) => {
    const [eventType, value] = e.key.split('=>');
    e[eventType] = value;
    onClick && onClick(e);
  }, [onClick]);

  return useMemo(() => {
    return (
      <Menu {...menuProps} onClick={handleClick}>{options.map((item, index) => renderMenu(item, index, t))}</Menu>
    );
  }, [menuProps, handleClick, options, t]);

};
