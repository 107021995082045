import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {enquireScreen} from 'enquire-js';

const ResponsiveContext = createContext();


let _isMobile;
enquireScreen((b) => {
  _isMobile = b;
});

function ResponsiveProvider({children}) {
  const [isMobile, setMobile] = useState(_isMobile);

  useEffect(() => {
    enquireScreen((b) => {
      setMobile(!!b);
    });
  }, []);

  return (
    <ResponsiveContext.Provider value={{
      isMobile: isMobile
    }}>
      {children}
    </ResponsiveContext.Provider>
  );
}

function useResponsive() {
  const context = useContext(ResponsiveContext);
  if (context === undefined) {
    throw new Error('useResponsive must be used within a ResponsiveProvider');
  }
  const {
    isMobile
  } = context;

  return {
    isMobile
  };
}

ResponsiveProvider.propTypes = {
  isMobile: PropTypes.bool,
  children: PropTypes.any
};


export {ResponsiveProvider, useResponsive};
