import React, {memo} from 'react';
import map from 'lodash/map';
import FilterItem from './FilterItem';
import {useTableFilters} from '@/components/TableFilters/contexts/TableFiltersContext';

const FilterList = (props) => {
  const {activeFilters} = useTableFilters();

  return <div className="w-full flex flex-col lg:flex-row lg:flex-wrap justify-end">
    {map(activeFilters, filter => <FilterItem key={filter?.name} filter={filter} {...props}/>)}
  </div>;
};

export default memo(FilterList);