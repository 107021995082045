import { useMemo } from 'react';
import { useUser } from '@dofleini/security';
import { useTranslation } from 'react-i18next';
import { navigation as defaultNavigationConfig } from '@/settings';

/**
 * Process a navigation
 *   1. translate the title
 *   1. Filter and process each navigation child inside the menu field
 * @param {function} t translation function
 * @param {function} hasPermission function to check permission
 * @param {Object} navigation object
 * @param {String} path parent path
 * @param {Boolean} isParentMenu if the element is a parent menu
 * @return {Object} navigation parsed
 * */
function processNavigation(t, hasPermission, {title, menu, link, ...rest}, path, isParentMenu) {
  const currentPath = (path || '') + (rest.path || '');
  const currentLink = link ? currentPath + link : link;
  menu = menu ? processMenu(t, hasPermission, menu, currentPath, isParentMenu) : [];

  title = t(title || currentLink);

  return {
    title,
    menu: menu.length ? menu : undefined, //not have children
    link: currentLink,
    ...rest
  };
}

/**
 * Process a menu list
 *   1. filter by permissions
 *   1. process each child navigation
 * @param {function} t translation function
 * @param {function} hasPermission function to check permission
 * @param {Array} menus array of navigations
 * @param {String} path parent path
 * @param {Boolean} isParentMenu if the element is a parent menu
 * @return {Object} menu parsed
 * */
function processMenu(t, hasPermission, menus = [], path, isParentMenu) {
  const filterMapped = menus.map(navigation => processNavigation(t, hasPermission, navigation, path, isParentMenu));

  return filterMapped.filter((menu) => {
    if (!menu) return false;
  
    const allow = menu.permissions && hasPermission(menu.permissions, isParentMenu);
    return !menu.permissions || allow;
  });
}


/**
 * Process a navigation
 * @param {function} t :translation function
 * @param {function} hasPermission function to check permission
 * @param {Object | Array} navigation navigation object
 * @return {Object | Array} navigations parsed
 * */
function processAllNavigation(t, hasPermission, navigation) {
  if (Array.isArray(navigation))
    return processMenu(t, hasPermission, navigation, null, true);
  return {
    menu: processMenu(t, hasPermission, navigation.menu, null)
  };
}

const useNavigation = (navigation = defaultNavigationConfig) => {
  const {hasPermission} = useUser();
  const {t} = useTranslation('navigation');

  return useMemo(() => {
    return processAllNavigation(t, hasPermission, navigation);
  }, [hasPermission, navigation, t]);
};

export default useNavigation;
