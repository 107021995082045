import {ApiClientService} from '@dofleini/security';
import {authentication} from '@/settings';

class ForgotPassword {

  constructor() {
    this.service = ApiClientService;
  }

  init = (params) => {
    try {
      return this.service.post(`${authentication.path}/api/forgot-password/init`, params);
    } catch (error) {
      console.error(error);
    }
  };

  check = (params) => {
    try {
      return this.service.post(`${authentication.path}/api/forgot-password/check`, params);
    } catch (error) {
      console.error(error);
    }
  };

  finish = (params) => {
    try {
      return this.service.post(`${authentication.path}/api/forgot-password/finish`, params);
    } catch (error) {
      console.error(error);
    }
  }
}

export default new ForgotPassword();
