import React from 'react';
import Email from '@/components/Email/index';
import {Avatar, Typography} from 'antd';
import classNames from 'classnames';
import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

/**
 * This is not a component,
 * it is a function to render the Email component in a table cell
 * */
export const renderEmail = (email, item) => <Email item={item}>{email}</Email>;


/**
 * This is not a component,
 * it is a function to render the Avatar component in a table cell
 * */
export const renderAvatar = (avatar) => <Avatar src={avatar}/>;

/**
 * This is not a component,
 * it is a function to render the Squared Avatar component in a table cell
 * */
export const renderSquareAvatar = (avatar) => <Avatar shape="square" src={avatar}/>;
/**
 * This is not a component,
 * it is a function to render the Booleans values in a table cell
 * */
export const renderBool = value => (
  <span className={classNames({ 'text-primary': value }, 'text-xl leading-none')}>
    {value && value !== '0' ? <CheckCircleOutlined/> : <MinusCircleOutlined/>}
  </span>
);

/**
 * This is not a component,
 * it is a function to render the description values for table
 * */
export const renderDescription = (value) => {
  return (
    <Typography.Paragraph ellipsis={{ rows: 2, symbol: '...' }} className="mb-0">
      {value}
    </Typography.Paragraph>);
};
