import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import compose from 'lodash/fp/compose';
import filterFP from 'lodash/fp/filter';
import mapFieldItems from '@/utils/mapFieldItems';

export const createFieldsHook = (translation, fields) => {
  return (isViewMode) => {
    const {t} = useTranslation(translation);

    return useMemo(
      () => compose(
        mapFieldItems(t),
        filterFP(({onlyView, onlyEdit}) => {
          if (onlyView) return isViewMode;
          if (onlyEdit) return !isViewMode;
          return true;
        }),
      )(fields), [isViewMode, t]);
  };
};

