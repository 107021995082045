/* eslint-disable  react-hooks/exhaustive-deps */
import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';
import omit from 'lodash/omit';

const MultiDialogsContext = createContext();

function MultiDialogsContextProvider(props) {
  const [dialogs, setDialogs] = useState({});

  const openDialog = useCallback((dialog, payload) => {
    setDialogs({
      ...dialog,
      [dialog]: payload
    });
  }, []);

  const closeDialog = useCallback((dialog) => {
    setDialogs(omit(dialogs, [dialog]));
  }, []);

  return (
    <MultiDialogsContext.Provider value={{dialogs, openDialog, closeDialog}} {...props} />
  );
}

function useMultiDialogsContext(dialog) {
  const context = useContext(MultiDialogsContext);
  if (context === undefined) {
    throw new Error('useDialogContext must be used within a MultiDialogsContextProvider');
  }

  const {dialogs, openDialog, closeDialog} = context;

  return useMemo(() => {
    const _openDialog = (payload) => openDialog(dialog, payload);

    const _closeDialog = () => closeDialog(dialog);

    const isOpen = !!dialogs[dialog];

    const payload = isOpen && dialogs[dialog];

    return {
      openDialog: _openDialog,
      closeDialog: _closeDialog,
      isOpen,
      payload,
    };
  }, [dialogs, openDialog, closeDialog]);
}

export {MultiDialogsContextProvider, useMultiDialogsContext};
