import React, {memo, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, message as alertMessage} from 'antd';
import FloatContainer, {TYPE_CONTAINER} from '@/components/FloatContainer';
import {useMultiDialogsContext} from '@/contexts/MultiDialogsContext';
import {useResponsive} from '@/contexts/ResponsiveContext';
import {useMutation, useQueryClient} from 'react-query';
import {referenceError} from '@/settings';
import { CopyOutlined, GroupOutlined } from '@ant-design/icons';

export const createReferenceWarning = ({translation, message, service, module}) => {
  const isActive = referenceError?.active;
  const referenceKeyError = referenceError?.keyError;
  const dialogModule = `reference-warning-dialog-${module}`;
  
  const useReferenceWarningDialogsContext = () => useMultiDialogsContext(dialogModule);
  
  const useErrorMiddleware = () => {
    const {openDialog} = useReferenceWarningDialogsContext();
    
    return ({
      onForce,
      onRecovery
    }) => err => {
      const refError = err?.response?.data?.reference;
      const ref = err?.response?.data?.ref;
      
      if (isActive && `${refError}` === `${referenceKeyError}` && ref) {
        openDialog({
          ref,
          onForce,
          onRecovery
        });
      }
    };
  };
  
  const ReferenceWarningDialog = (props) => {
    const {t} = useTranslation(translation);
    const {isOpen, closeDialog, payload} = useReferenceWarningDialogsContext();
    const {isMobile} = useResponsive();
    const queryClient = useQueryClient();
    
    const {onForce, onRecovery, ref} = useMemo(() => payload || {}, [payload]);
    
    const {mutate: handleRecovery, isLoading} = useMutation(() => service.recover(ref),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(module);
          onRecovery();
          alertMessage.success(t('recoverySuccess'));
          closeDialog();
        }
      });
      
    const handleForce = useCallback(() => {
      onForce();
      closeDialog();
    }, [closeDialog, onForce]);
    
    return (
      <FloatContainer
        type={isMobile ? TYPE_CONTAINER.DRAWER : TYPE_CONTAINER.MODAL}
        closable={false}
        visible={isOpen}
        footer={null}
        {...props}
      >
        <div>
          {t('recoveryTitle')}
        </div>
        <div className="flex flex-col mt-4">
          <div>
            {t(message || 'messageReferenceWarningDialog')}
          </div>
          <Button icon={<CopyOutlined/>} ghost className="my-2" key="r-forceCreate"
            type="primary" onClick={handleForce}>
            {t('forceCreate')}
          </Button>
          <Button icon={<GroupOutlined/>} ghost className="my-2" key="r-recovery"
            type="primary" danger loading={isLoading} onClick={handleRecovery}>
            {t('recovery')}
          </Button>
          <Button className="my-2" key="r-cancel" onClick={closeDialog}>
            {t('cancel')}
          </Button>
        </div>
      </FloatContainer>
    );
  };
  
  return {
    useErrorMiddleware,
    useReferenceWarningDialogsContext,
    ReferenceWarningDialog: memo(ReferenceWarningDialog)
  };
};
