import React from 'react';
import PropTypes from 'prop-types';
import {createPaginationHook} from '@/utils/createPaginationHook';
import {createContextList} from '@/utils/createContextList';
import {FilterProvider} from '@/contexts/FilterContext';
import {useTranslation} from 'react-i18next';
import {useResponsive} from '@/contexts/ResponsiveContext';
import TableGrid from '@/components/TableGrid';

export const
  ADD_HANDLER_DIALOG = 'handler-dialog';

export const createEntityContainer = ({
  module,
  service,
  translation,
  translationPrefix = '',
  route,
  searchPlaceholder,
  pageConfig,
  childrenColumnName,
  isExpandable,
  expandable,
  isCustomDialog,
  customDialogName,
  ...rest
}) => {

  const useTablePagination = createPaginationHook(module, service);

  const {Provider, useList} = createContextList(useTablePagination, module, pageConfig);

  const ContainerList = ({children, top, ...props}) => {
    const {t} = useTranslation(translation || module);
    const {isMobile} = useResponsive();

    return (
      <FilterProvider id={module}>
        <Provider>
          {top}
          <TableGrid
            isExpandable={isExpandable}
            expandable={expandable}
            childrenColumnName={childrenColumnName}
            translation={translation || module}
            scroll={{x: true}}
            addLabel={t(`${translationPrefix}.create`)}
            tableTitle={t(`${translationPrefix}.pageTitle`)}
            useTableData={useList}
            canSelect={!isMobile}
            showHeader={!isMobile}
            translationPrefix={translationPrefix}
            route={route}
            searchPlaceholder={searchPlaceholder && t(searchPlaceholder)}
            isCustomDialog={isCustomDialog}
            customDialogName={customDialogName}
            {...rest}
            {...props}
          />
          {children}
        </Provider>
      </FilterProvider>
    );
  };

  ContainerList.propTypes = {
    children: PropTypes.any,
    top: PropTypes.any
  };

  return {
    ContainerList,
    useList,
    ListProvider: Provider,
    useTablePagination
  };
};
