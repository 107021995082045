import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Layout} from 'antd';
import AppLayout from '@/layout/AppLayout';
import {ProfileDialog} from '@/modules/admin/containers/Profile';

const {Content} = Layout;

const MainLayout = ({children}) => {

  return (
    <Layout style={{minHeight: '100vh'}}>
      <AppLayout>
        <Content className={'m-0 mt-4 lg:m-0'}>
          {children}
          <ProfileDialog headerProps={{canRemove: false, canEdit: true}}/>
        </Content>
      </AppLayout>
    </Layout>
  );
};

export default memo(MainLayout);

MainLayout.propTypes = {
  children: PropTypes.any
};
