import {ApiClientService, EntityApiService} from '@dofleini/security';
import {authentication} from '@/settings';

class RolApiService extends EntityApiService {

	getRoles = (params) => ApiClientService.post(this.getPath('/roles/search'), params);

	deleteRol = (params) => ApiClientService.delete(this.getPath(`/roles/${params.rolId}`));

	updateRol = (params) => ApiClientService.patch(this.getPath(`/roles/${params._id}`), params);

	addRol = (params) => ApiClientService.post(this.getPath('/roles'), params);

	getPermissions = (params) => ApiClientService.post(this.getPath('/permissions/search'), params);

	setRolPermissions = (params) => ApiClientService.post(this.getPath(`/roles/${params.rolId}/permission`), params.payload);
}

export default new RolApiService(`${authentication.path}/api/security`);
