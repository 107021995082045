import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, Menu, message} from 'antd';
import {ControlOutlined} from '@ant-design/icons';
import {USER_STATUS} from '@/modules/admin/contants/users';
import map from 'lodash/map';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import UserApiService from '@/modules/admin/services/UserApiService';
import UserStatus from '@/modules/admin/components/UserStatus';

const statusFilter = {
  DELETED: true,
  UNVERIFIED: true,
};

const UserStatusSelect = ({users, onChange}) => {
  const {t} = useTranslation('admin');
  const {mutateAsync, isLoading} = useMutation(UserApiService.updateStatus);
  const hasUsers = users && Array.isArray(users);
  const handleChange = useCallback(async ({key}) => {
    if (hasUsers) {
      try {
        await mutateAsync({users, status: key});
        onChange && onChange(key);
      } catch (e) {
        message.error('Error');
      }
    }
  }, [hasUsers, mutateAsync, users, onChange]);

  const menu = (
    <Menu onClick={handleChange}>
      {
        map(USER_STATUS, (status) => !statusFilter[status] ? (
          <Menu.Item key={status}>
            <UserStatus status={status} full help/>
          </Menu.Item>
        ) : '')
      }
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button loading={isLoading} type="link" disabled={isLoading || !hasUsers}
        icon={<ControlOutlined/>}>{t('user.changeStatus')}</Button>
    </Dropdown>
  );

};

export default memo(UserStatusSelect);

UserStatusSelect.propTypes = {
  onChange: PropTypes.func,
  users: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};
