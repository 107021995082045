export const isRelativeURL = (url) => {
  return url && typeof (url) === 'string' && url.startsWith('/');
};

export const getAbsoluteURLPath = (url, baseUrl) => {
  const finalBaseURL = baseUrl || process.env.REACT_APP_FILE_STORAGE || '';
  if (url && typeof url === 'string' && !url.startsWith(baseUrl) && isRelativeURL(url)) {
    return finalBaseURL + url;
  } else {
    return url;
  }
};
