/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 18/9/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as Logo} from '@/assets/images/logo/logo-white.svg';
import './index.less';

/** Images */
import dofleini from '@/assets/images/dofleini.svg';
import etecsa from '@/assets/images/etecsa.png';
import classnames from 'classnames';

const WelcomeLeftSidebar = ({children, className}) => {
  // const {t} = useTranslation('common');
  return (
    <div
      className={classnames('w-full flex flex-col min-h-screen items-center justify-between wrapper-welcome-left-sidebar text-white relative z-10 py-8 md:py-12', className)}>
      <div className="welcome-header">
        <div className="logo mt-2"><Logo/></div>
      </div>

      <div className="px-4 w-full flex flex-col items-center justify-center mb-8">
        {children}
        {/*<div className="download-app my-8 flex items-center justify-center">{t('welcomeSidebar.app')}</div>*/}
        {/*<div className="title text-center mb-5">{t('welcomeSidebar.info')}</div>*/}
        {/*<div className="description text-center mb-5">{t('welcomeSidebar.infoSubtext')}</div>*/}
        {/*<div className="request-audit mb-5">*/}
        {/*  <Button type="primary" size="large" shape="round">*/}
        {/*    {t('welcomeSidebar.register')}*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </div>

      <div className="welcome-footer mb-4">
        <div className="trusty-companies w-full flex items-center justify-center">
          <img src={dofleini} alt="Dofleini S.R.L" className={'mr-8'}/>
          <img src={etecsa} alt="ETECSA"/>
        </div>
      </div>
    </div>
  );
};

WelcomeLeftSidebar.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export default memo(WelcomeLeftSidebar);

WelcomeLeftSidebar.defaultProps = {
  className: ''
};
