import {ERRORS} from '@/settings/errors';

export const isNetworkError = (e) => {
  return e.response && !e.status;
};

export const getResponseError = (error) => {
  return (error.response && error.response.data) || error;
};

export const handleResponse = async (promise, transform) => {
  try {
    return await promise.then(({data}) => transform ? transform(data) : data);
  } catch (e) {
    if (isNetworkError(e))
      return Promise.reject({reference: ERRORS.NETWORK_ERROR, message: 'Network error'});
    return Promise.reject(getResponseError(e));
  }
};
