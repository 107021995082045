import {renderEmail} from '@/components/CellRenders';
import {renderUser} from '@/modules/admin/components/UserCell';
import {renderUserStatus} from '@/modules/admin/components/UserStatus';
import {renderUserActions} from '@/modules/admin/components/UserRowActions';
import { i18n } from '@/settings';

export const columns = [
  {
    key: 'name',
    title: 'name',
    dataIndex: 'fullName',
    render: renderUser
  },
  {
    key: 'email',
    title: 'email',
    dataIndex: 'email',
    render: renderEmail,
    responsive: ['lg'],
  },
  {
    key: 'phone',
    title: 'phone',
    dataIndex: 'phone',
    responsive: ['lg'],
  },
  {
    key: 'status',
    title: 'status',
    dataIndex: 'status',
    render: renderUserStatus,
    responsive: ['md'],
  },
  {
    key: 'createdAt',
    title: 'registerAt',
    type: 'date',
    dataIndex: 'createdAt',
    responsive: ['lg'],
  },
  {
    key: 'role',
    title: 'roles',
    type: 'list',
    setting: {
      tag: true,
      renderField: 'role.name',
      customMapFunc: (item) => ({...item, role: {...item.role, name: i18n.t(`admin:rol.${item?.role?.name}`)}}),
      conditionalColor: (item) => item?.role?.superAdmin ? 'processing' : undefined
    },
    dataIndex: 'roles',
    responsive: ['lg'],
  },
  {
    key: 'actions',
    title: 'actions',
    dataIndex: 'actions',
    align: 'center',
    width: 100,
    render: renderUserActions
  }
];
