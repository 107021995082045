import React, {memo} from 'react';
import PropTypes from 'prop-types';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';

const Editor = ({value, onChange, disabled}) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        language: 'es',
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
      }}
      disabled={disabled}
      data={value}
      onChange={(event, editor) => {
        onChange({target: {value: editor.getData()}});
      }}
    />
  );
};

export default memo(Editor);

Editor.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export const renderEditor = props => <Editor {...props} />;
