import React, {memo} from 'react';
import {Skeleton} from 'antd';

const FormSkeleton = () => {
  return (
    <>
      <Skeleton/>
      <Skeleton/>
      <Skeleton/>
    </>
  );
};

export default memo(FormSkeleton);
