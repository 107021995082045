import moment from 'moment';

export const getTodayRange = () => {
  return [moment(), moment()];
};

export const getThisWeek = () => {
  return [moment().day(0), moment().day(6)];
};

export const getLastSevenDays = () => {
  return [moment().subtract(7, 'days'), moment()];
};

export const getThisMonth = () => {
  return [moment().startOf('month'), moment().endOf('month')];
};

export const getLastThirtyDays = () => {
  return [moment().subtract(30, 'days'), moment()];
};

export const getLastMonth = () => {
  return [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')];
};
