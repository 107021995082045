import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const QuestionLink = ({question, link, linkText}) => {


  return (
    <div>
      <span>{question}</span>{' '}<Link to={link}>{linkText}</Link>
    </div>
  );

};

export default memo(QuestionLink);

QuestionLink.propTypes = {
  link: PropTypes.string,
  linkText: PropTypes.string,
  question: PropTypes.string
};
