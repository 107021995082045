import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {ImUser} from 'react-icons/im';
import {Avatar} from 'antd';
import {getFromSource} from '@guia/business';

export const EMPTY_AVATAR_VIEW = {
  INITIALS: 'INITIALS',
  DEFAULT: 'DEFAULT'
};


const getInitials = (text) => {
  return text && (text.substr(0, Math.min(2, text.length))).toUpperCase();
};

const UserAvatar = ({user, src, emptyAvatarView, ...props}) => {
  const {avatar, avatarOriginal} = user;

  const userAvatar = useMemo(() => src || avatar || avatarOriginal, [src, avatar, avatarOriginal]);
  const initials = useMemo(() => getInitials(user?.firstName), [user]);


  return (
    <Avatar
      src={getFromSource(userAvatar)}
      icon={!userAvatar && emptyAvatarView === EMPTY_AVATAR_VIEW.DEFAULT &&
      <span className={'anticon'}><ImUser/></span>}
      {...props}
    >
      {!userAvatar && emptyAvatarView === EMPTY_AVATAR_VIEW.INITIALS && initials}
    </Avatar>
  );
};

export default memo(UserAvatar);

UserAvatar.propTypes = {
  user: PropTypes.object,
  src: PropTypes.string,
  emptyAvatarView: PropTypes.string,
};

UserAvatar.defaultProps = {
  user: {},
  emptyAvatarView: EMPTY_AVATAR_VIEW.INITIALS,
};
