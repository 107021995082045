import { createGetOneHook } from '@/utils/createGetOneHook';
import { createFieldsHook } from '@/utils/createFieldsHook';
import UserApiService from '@/modules/admin/services/UserApiService';
import { CACHE_KEY_USER, fields } from '@/modules/admin/contants/users';
import { useLocation } from 'react-router-dom';
import { useUser } from '@dofleini/security';
import { useMemo } from 'react';

export const useGetOneUser = createGetOneHook(
  UserApiService,
  'id',
  CACHE_KEY_USER,
);

const useFieldsBase = createFieldsHook('admin', fields);

export const useFields = (isViewMode, form, id) => {
  const fields = useFieldsBase(isViewMode);
  const { pathname } = useLocation();
  const { user } = useUser();

  const isCreate = useMemo(() => pathname.endsWith('/create') && !id, [pathname, id]);

  return useMemo(() => {
    let fieldsMap;
    fieldsMap = isCreate ? fields.filter(({ key }) => key !== 'status') : fields;
    if (!isViewMode && user?._id !== id) {
      fieldsMap = fieldsMap.filter(fiel => fiel?.key === 'roles');
    }
    return fieldsMap;
  }, [fields, id, isCreate, isViewMode, user?._id]);
};
