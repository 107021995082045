import React, {memo, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Tooltip as AntTooltip} from 'antd';

export const Tooltip = ({parentContainer, ...props}) => {
  const ref = useRef();

  const getPopupContainer = useCallback(() => {
    return ref.current;
  }, []);

  return (
    <div ref={ref}>
      <AntTooltip {...props} getPopupContainer={parentContainer && getPopupContainer}/>
    </div>
  );
};

Tooltip.propTypes = {
  parentContainer: PropTypes.bool,
};

export default memo(Tooltip);
