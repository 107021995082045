import React, {memo} from 'react';
import AdminHeader from '@/components/TableGrid/TableHeader';
import PropTypes from 'prop-types';
import TableData from '@/components/TableGrid/TableData';
import {TableContextProvider} from '@/contexts/tableGridContact';
import EntityMenu from '@/components/EntityMenu';
import {useDialogsContext} from '@/contexts/DialogsContext';
import {ADD_HANDLER_DIALOG} from '@/utils/createEntityContainer';

const TableGrid = ({
  tableTitle,
  extras,
  columns,
  useTableData,
  canSelect,
  rowActions,
  actionsLabel,
  addLabel,
  translation,
  selectedActions,
  filters,
  canAdd,
  route,
  useCreatePath,
  useMapColumns,
  searchPlaceholder,
  canSearch,
  isExpandable,
  childrenColumnName,
  expandable,
  isCustomDialog,
  customDialogName,
  ...props
}) => {
  const {openDialog} = useDialogsContext(!isCustomDialog ? ADD_HANDLER_DIALOG : customDialogName);

  if (rowActions && !columns.some(c => c.dataIndex === 'actions')) {
    columns.push(
      {
        title: actionsLabel,
        dataIndex: 'actions',
        width: 100,
        align: 'center',
        // eslint-disable-next-line react/display-name
        render: (text, record) => (
          <EntityMenu data={record}/>
        )
      }
    );
  }

  return (
    <div className={'px-3 pb-3 lg:px-4 lg:pb-4 lg:px-6 lg:pb-6'}>
      <TableContextProvider columns={columns} translation={translation} useMapColumns={useMapColumns}>
        <AdminHeader
          title={tableTitle}
          onAdd={openDialog}
          useCreatePath={useCreatePath}
          route={route}
          addLabel={addLabel}
          extras={extras}
          hasTable
          canAdd={canAdd}
          canSearch={canSearch}
          filters={filters}
          translation={translation}
          searchPlaceholder={searchPlaceholder}
        />
        <TableData
          isExpandable={isExpandable}
          expandable={expandable}
          selectedActions={selectedActions}
          tableHook={useTableData}
          canSelect={canSelect}
          {...props}/>
      </TableContextProvider>
    </div>
  );

};

TableGrid.propTypes = {
  actionsLabel: PropTypes.string,
  addLabel: PropTypes.string,
  canSelect: PropTypes.bool,
  canSearch: PropTypes.bool,
  columns: PropTypes.array,
  extras: PropTypes.node,
  filters: PropTypes.any,
  onAdd: PropTypes.func,
  isExpandable: PropTypes.bool,
  isCustomDialog: PropTypes.bool,
  route: PropTypes.string,
  rowActions: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  selectedActions: PropTypes.func,
  tableHook: PropTypes.func,
  tableTitle: PropTypes.string.isRequired,
  translation: PropTypes.string,
  translationPrefix: PropTypes.string,
  useCreatePath: PropTypes.bool,
  useTableData: PropTypes.func.isRequired,
  canAdd: PropTypes.bool,
  useMapColumns: PropTypes.any,
  searchPlaceholder: PropTypes.any,
  childrenColumnName: PropTypes.string,
  customDialogName: PropTypes.string,
  expandable: PropTypes.object,
};

TableGrid.defaultProps = {
  canAdd: true,
  canSearch: true,
  isCustomDialog: false,
};

export default memo(TableGrid);
