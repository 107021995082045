import React from 'react';
import {Checkbox, Skeleton} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import TagList from '@/components/TagList';
import {showPrice} from '@/utils/utils';
import User from '@/components/User';

const Cell = ({children, ...props}) => {
  return <span {...props}>{children}</span>;
};

export const cellRender = (value, record, index, type, render, {setting, conditionalProps, ...restOptions}) => {
  if (record.__isLoading) {
    if (type === 'image')
      return <Skeleton.Image/>;
    return <Skeleton paragraph={false} active className={'skeleton-title-m-0'}/>;
  }
  const morePros = conditionalProps ? conditionalProps(record) : {};
  if (typeof render === 'function')
    return render(value, record, index);

  if (type === 'list')
    return <TagList value={value} {...setting}/>;

  if (type === 'money')
    return <Cell {...setting} {...morePros}>{showPrice(value, restOptions.currency)}</Cell>;

  if (type === 'user')
    return <User user={value} {...setting}/>;

  if (type === 'bool')
    return <Cell {...setting} {...morePros}>{<Checkbox checked={value}/>}</Cell>;

  if (type === 'date' && value)
    return <Cell {...setting} {...morePros}>{moment(value).format('DD/MM/YYYY').toString()}</Cell>;
  return value;
};

Cell.propTypes = {
  children: PropTypes.any
};
