import React, {memo, useCallback, useMemo, useState, useEffect, useRef} from 'react';
import {Select, Tag} from 'antd';
import {EMAIL_PROVIDERS} from '../../constants';
import UserAvatar from '@/components/UserAvatar';
import {getUserFullName} from '@/utils/userFullName';
import findMatch from '@/utils/findMatch';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import find from 'lodash/find';
import includes from 'lodash/includes';
import fpMap from 'lodash/fp/map';
import fpFilter from 'lodash/fp/filter';
import fpCompose from 'lodash/fp/compose';
import {useUserPagination} from '@/modules/admin/containers/UserEntityContainer';

const {Option} = Select;

const createMapEmailsValue = (prefix) => (item) => {
  const value = `${prefix}@${item}`;
  return {value, label: value};
};

const renderUser = (user) => {
  return <>
    <UserAvatar user={user} size={18}/>
    <span className="ml-1 p-px">{getUserFullName(user)}</span>
  </>;
};

function createLabelRender(users) {
  return (arg) => {
    switch (typeof arg) {
      case 'object':
        return renderUser(arg);
      case 'string':
      default:
        // eslint-disable-next-line no-case-declarations
        const item = find(users, {'_id': arg});
        return item ? createLabelRender(users)(item) : arg;
    }
  };
}

const handleFilter = (inputValue, option) => {
  switch (typeof option?.item) {
    case 'object':
      return findMatch(inputValue, getUserFullName(option?.item));
    default:
      return findMatch(inputValue, option?.item);
  }
};

const UserEmailSearch = ({initUsers, initEmails, value, onChange, ...props}) => {
  const hotUserOptionsRef = useRef([]);
  const [searchValue, setSearchValue] = useState('');

  const queryConfig = useMemo(() => {
    return {enabled: !!searchValue};
  }, [searchValue]);

  const {data: users = [], isLoading} = useUserPagination(searchValue, null, queryConfig);

  const initUserValues = useMemo(() => {
    hotUserOptionsRef.current = initUsers;
    return compact(initUsers).map(({_id}) => _id);
  }, [initUsers]);

  const defaultValue = useMemo(() => [...initUserValues, ...initEmails], [initEmails, initUserValues]);

  useEffect(() => {
    onChange({target: {value: defaultValue}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const userOptions = useMemo(() => {
    return fpCompose(
      fpMap(user => ({
        value: user._id,
        label: user
      })),
      fpFilter(({_id}) => !includes(value, _id))
    )(users);
  }, [users, value]);

  const options = useMemo(() => {
    if (/\w+@.*/.test(searchValue)) {
      const [userEmail] = searchValue.split('@');
      return EMAIL_PROVIDERS.map(createMapEmailsValue(userEmail));
    }
    return userOptions;
  }, [searchValue, userOptions]);

  //search handle

  const onSelect = useCallback(() => {
    setSearchValue();
  }, []);

  //render values
  const labelRender = useMemo(() => {
    return createLabelRender([...hotUserOptionsRef.current, ...users]);
  }, [users]);

  const tagRender = useCallback((props) => {
    // eslint-disable-next-line react/prop-types
    const {label, closable, onClose} = props;
    return (
      <Tag closable={closable} onClose={onClose} className="py-px">
        {labelRender(label)}
      </Tag>
    );
  }, [labelRender]);

  return (
    <Select
      value={value}
      onChange={onChange}
      className="w-full"
      mode="multiple"
      tagRender={tagRender}
      onSearch={setSearchValue}
      filterOption={handleFilter}
      notFoundContent={null}
      onSelect={onSelect}
      loading={isLoading}
      {...props}
    >
      {searchValue && <Option key={`__${searchValue}`} value={searchValue} item={searchValue}>{searchValue}</Option>}
      {options.map(({value, label}, index) => (
        <Option key={`${value}-${index}`} value={value} item={label}>{labelRender(label)}</Option>
      ))}
    </Select>
  );
};

UserEmailSearch.propTypes = {
  initUsers: PropTypes.array,
  initEmails: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

UserEmailSearch.defaultProps = {
  initUsers: [],
  initEmails: [],
  value: [],
};

export default memo(UserEmailSearch);
